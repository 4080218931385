import { Fragment, useEffect, useState } from 'react'
import {
  Hidden,
  MenuItem,
  Paper,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import { isEmpty } from 'lodash'

import {
  MenuButton,
  MUI,
  NoData,
  PaperHeader,
  Permitted,
  Table as TableComponent,
} from 'components'

import { useFetch } from 'hooks'

import { GovernanceProjectType } from 'types/governanceProject.types'

import { LinkedGovernanceProjectsTableType } from './linkedGovernanceProjectsTable.types'

import service from 'service'
import constants from 'constants/index'

const LinkedGovernanceProjectsTable = ({
  questionnaire,
  questionnaireId,
  setIsLoading,
}: LinkedGovernanceProjectsTableType) => {
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(5)

  const { response, loading } = useFetch(
    service.dponet.questionnaires.getVinculatedProjects,
    {
      quizTemplateId: questionnaireId,
      params: {
        page,
        perPage,
      },
    },
    [questionnaireId, questionnaire, page, perPage]
  )

  const linkedProjects: GovernanceProjectType[] =
    response?.data?.governanceProjectTemplates

  const handleChangePage = (newPage: number) => {
    setPage(newPage + 1)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newPerPage = parseInt(event.target.value, 10)
    setPerPage(newPerPage)
  }

  useEffect(() => {
    loading ? setIsLoading(true) : setIsLoading(false)
  }, [loading])

  return (
    <Fragment>
      <Paper variant="outlined">
        <PaperHeader title="Projetos de Governança Vínculados" px={3} />
        {loading || isEmpty(linkedProjects) ? (
          <NoData
            title="Nenhum projeto de governança vinculado a este questionário."
            // subTitle="Você pode criar novos projetos de governança através do menu Projetos de Governança."
            withoutPaper
          />
        ) : (
          <Fragment>
            <TableContainer>
              <TableComponent aria-label="Listagem de empresas vínculadas">
                <TableHead>
                  <TableRow>
                    <MUI.TableCell width={100} align="left">
                      ID
                    </MUI.TableCell>
                    <MUI.TableCell align="left">Nome</MUI.TableCell>
                    <MUI.TableCell width={100}>
                      Projetos vinculados
                    </MUI.TableCell>
                    <MUI.TableCell width={150} align="right">
                      Ações
                    </MUI.TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {linkedProjects?.map((governanceProjectTemplate) => (
                    <TableRow key={governanceProjectTemplate?.id}>
                      <MUI.TableCell align="left">
                        {governanceProjectTemplate?.id}
                      </MUI.TableCell>
                      <MUI.TableCell align="left">
                        {governanceProjectTemplate?.name}
                      </MUI.TableCell>
                      <MUI.TableCell align="center">
                        {governanceProjectTemplate?.governanceProjectsCount}
                      </MUI.TableCell>
                      <Permitted
                        tag={constants.permissionTags.QUESTIONNAIRES.MANAGE}
                      >
                        <MUI.TableCell align="right">
                          <MenuButton id="teste">
                            <MenuItem onClick={() => {}}>Visualizar</MenuItem>
                          </MenuButton>
                        </MUI.TableCell>
                      </Permitted>
                    </TableRow>
                  ))}
                </TableBody>
              </TableComponent>
            </TableContainer>
            <TablePagination
              component="div"
              count={response?.data?.meta?.totalCount || 0}
              onPageChange={(_, page) => handleChangePage(page)}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page - 1}
              rowsPerPage={perPage}
              rowsPerPageOptions={[5, 10, 25, 100]}
              labelRowsPerPage={<Hidden smDown>Por página</Hidden>}
              nextIconButtonProps={{ size: 'small' }}
              backIconButtonProps={{ size: 'small' }}
            />
          </Fragment>
        )}
      </Paper>
    </Fragment>
  )
}

export default LinkedGovernanceProjectsTable
