import { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import { ArrowRight as ArrowRightIcon } from 'react-feather'
import {
  Button,
  Hidden,
  TableBody,
  TableContainer,
  TableHead,
  Tooltip,
} from '@mui/material'
import { reverse } from 'named-urls'

import { MUI, Table as TableComponent, TableRow } from 'components'

import { formatters, validates } from 'helpers'

import { QuestionnairesTableType } from './questionnairesTable.types'

import routes from 'constants/routes'
import constants from 'constants/index'

const QuestionnairesTable = ({ questionnaires }: QuestionnairesTableType) => {
  const navigate = useNavigate()

  const handleQuestionnaireEditing = (questionnaireId: number | string) => {
    navigate(
      reverse(routes.app.questionnaires.edit, {
        questionnaireId: questionnaireId,
      })
    )
  }

  return (
    <Fragment>
      <TableContainer>
        <TableComponent aria-label="Listagem de questionários">
          <TableHead>
            <TableRow>
              <MUI.TableCell align="left">ID</MUI.TableCell>
              <MUI.TableCell align="left" width={500} $maxWidth={500}>
                Questionário
              </MUI.TableCell>
              <MUI.TableCell align="left" width={200} $maxWidth={200}>
                Criado por
              </MUI.TableCell>
              <Hidden mdDown>
                <MUI.TableCell align="center">Última alteração</MUI.TableCell>
                <MUI.TableCell align="center">Questões</MUI.TableCell>
                <MUI.TableCell align="center">
                  Empresas vinculadas
                </MUI.TableCell>
              </Hidden>
              <MUI.TableCell align="center">Destinação</MUI.TableCell>
              <MUI.TableCell align="center" width={100} $maxWidth={100}>
                Status
              </MUI.TableCell>
              <MUI.TableCell align="right">Ações</MUI.TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {questionnaires?.map((questionnaire) => (
              <TableRow
                key={questionnaire?.id}
                onClick={() => handleQuestionnaireEditing(questionnaire?.id)}
              >
                <MUI.TableCell align="left">{questionnaire?.id}</MUI.TableCell>
                <Tooltip title={questionnaire?.kind}>
                  <MUI.TableCell align="left" width={500} $maxWidth={500}>
                    {questionnaire?.kind}
                  </MUI.TableCell>
                </Tooltip>
                <Tooltip title={questionnaire?.author?.name || 'DPOnet'}>
                  <MUI.TableCell align="left" width={200} $maxWidth={200}>
                    {questionnaire?.author?.name || 'DPOnet'}
                  </MUI.TableCell>
                </Tooltip>
                <Hidden mdDown>
                  <MUI.TableCell align="center">
                    {formatters.date.ptBrFormatDateTime(
                      questionnaire?.updatedAt
                    )}
                  </MUI.TableCell>
                  <MUI.TableCell align="center">
                    {questionnaire?.questionsCount}
                  </MUI.TableCell>
                  <MUI.TableCell align="center">
                    {questionnaire?.vinculatedCompaniesCount}
                  </MUI.TableCell>
                </Hidden>
                <MUI.TableCell>
                  {constants.questionnaire.TRANSLATED_INTERACTION_ENUMS[
                    questionnaire?.interaction
                  ] ?? 'Não definido'}
                </MUI.TableCell>
                <MUI.TableCell align="center" width={100} $maxWidth={100}>
                  <MUI.Chip
                    label={validates.questionnaires.labelTranslation(
                      questionnaire?.default &&
                        questionnaire?.vinculatedCompaniesCount === 0 &&
                        questionnaire?.governanceProjectsCount === 0
                        ? false
                        : questionnaire?.visible
                    )}
                    variant="filled"
                    size="small"
                    color={validates.questionnaires.statusColor(
                      questionnaire?.default &&
                        questionnaire?.vinculatedCompaniesCount === 0 &&
                        questionnaire?.governanceProjectsCount === 0
                        ? false
                        : questionnaire?.visible
                    )}
                    $fontWeight={500}
                    $width="100%"
                  />
                </MUI.TableCell>

                <MUI.TableCell align="right">
                  <Tooltip title="Visualizar detalhes">
                    <Button
                      variant="outlined"
                      color="neutral"
                      endIcon={<ArrowRightIcon size={20} />}
                      onClick={() =>
                        handleQuestionnaireEditing(questionnaire?.id)
                      }
                    >
                      <Hidden xlDown>DETALHES</Hidden>
                    </Button>
                  </Tooltip>
                </MUI.TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TableComponent>
      </TableContainer>
    </Fragment>
  )
}

export default QuestionnairesTable
