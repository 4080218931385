const CONTROLLER_TYPE = 'CONTROLLER'
const OPERATOR_TYPE = 'OPERATOR'
const SUBOPERATOR_TYPE = 'SUBOPERATOR'
const CO_CONTROLLER_TYPE = 'CO_CONTROLLER'

const CONTROLLER_LABEL = 'Controlador'
const OPERATOR_LABEL = 'Operador'
const SUBOPERATOR_LABEL = 'Suboperador'
const CO_CONTROLLER_LABEL = 'Co-controlador'

const TYPES = {
  CONTROLLER_TYPE,
  OPERATOR_TYPE,
  SUBOPERATOR_TYPE,
  CO_CONTROLLER_TYPE,
}

const LABELS = {
  CONTROLLER_LABEL,
  OPERATOR_LABEL,
  SUBOPERATOR_LABEL,
  CO_CONTROLLER_LABEL,
}

export default {
  TYPES,
  LABELS,
}
