import { Box, CircularProgress } from '@mui/material'

import { useOrganization } from 'hooks'

import { LoadingDependentRouteType } from './loadingDependentRoute.types'

const LoadingDependentRoute = ({ children }: LoadingDependentRouteType) => {
  const { loadedOrganizations } = useOrganization()

  if (loadedOrganizations) return <>{children}</>

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100%"
    >
      <CircularProgress color="primary" />
    </Box>
  )
}

export default LoadingDependentRoute
