import { Fragment, useState } from 'react'
import { Edit2 as Edit2Icon } from 'react-feather'
import {
  Button,
  TableBody,
  TableContainer,
  TableHead,
  Tooltip,
  Typography,
} from '@mui/material'

import { BasicDialog, MUI, Table as TableComponent, TableRow } from 'components'
import AnswerForm from '../../../AnswerForm'

import { formatters, validates } from 'helpers'

import { AnswerDataType, SubAnswerTableType } from './subAnswerTable.types'

const SubAnswerTable = ({
  childrenAnswerOptionTickets,
  refresh,
}: SubAnswerTableType) => {
  const [openModal, setOpenModal] = useState(false)
  const [formIsLoading, setFormIsLoading] = useState(false)
  const [answerData, setAnswerData] = useState<AnswerDataType>()

  const handleOpenModal = ({
    answerId,
    title,
    status,
    integrationValue,
  }: AnswerDataType) => {
    setOpenModal(true)
    setAnswerData({ answerId, title, status, integrationValue })
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  return (
    <Fragment>
      <TableContainer>
        <TableComponent aria-label="Listagem das respostas e suas configurações">
          <TableHead>
            <TableRow>
              <MUI.TableCell align="left" width={600} $maxWidth={600}>
                Resposta
              </MUI.TableCell>
              <MUI.TableCell align="center">Atualizado em</MUI.TableCell>
              <MUI.TableCell align="center">Status</MUI.TableCell>
              <MUI.TableCell align="center" width={130}>
                Ação
              </MUI.TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {childrenAnswerOptionTickets?.map((answerOption) => (
              <TableRow key={answerOption?.id}>
                <MUI.TableCell align="left" width={600} $maxWidth={600}>
                  <Tooltip title={answerOption?.title}>
                    <Typography
                      variant="inherit"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                    >
                      {answerOption?.title}
                    </Typography>
                  </Tooltip>
                </MUI.TableCell>
                <MUI.TableCell align="center">
                  {formatters.date.ptBrFormatDateTime(answerOption?.updatedAt)}
                </MUI.TableCell>
                <MUI.TableCell align="center">
                  <MUI.Chip
                    label={validates.privacyPortal.labelTranslation(
                      answerOption?.status
                    )}
                    variant="filled"
                    size="small"
                    color={validates.privacyPortal.statusColor(
                      answerOption?.status
                    )}
                  />
                </MUI.TableCell>
                <MUI.TableCell align="center">
                  <Button
                    variant="outlined"
                    startIcon={<Edit2Icon size={15} />}
                    onClick={() =>
                      handleOpenModal({
                        answerId: answerOption?.id,
                        title: answerOption?.title,
                        status: answerOption?.status,
                        integrationValue: answerOption?.integrationValue,
                      })
                    }
                  >
                    Editar
                  </Button>
                </MUI.TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TableComponent>
      </TableContainer>
      <BasicDialog
        open={openModal}
        onClose={handleCloseModal}
        title="Editar resposta"
        isSaving={formIsLoading}
        form="privacy-portal-answer-form"
      >
        <AnswerForm
          onClose={handleCloseModal}
          refresh={refresh}
          setIsLoading={setFormIsLoading}
          answerId={answerData?.answerId}
          title={answerData?.title}
          status={answerData?.status}
          integrationValue={answerData?.integrationValue}
          isEdit
        />
      </BasicDialog>
    </Fragment>
  )
}

export default SubAnswerTable
