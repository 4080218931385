import {
  LocaleType,
  LowerUpperFunctionType,
  OptionsType,
} from './changeCase.types'

// Regexps involved with splitting words in various case formats.
const SPLIT_LOWER_UPPER_RE = /([\p{Ll}\d])(\p{Lu})/gu
const SPLIT_UPPER_UPPER_RE = /(\p{Lu})([\p{Lu}][\p{Ll}])/gu

// Used to iterate over the initial split result and separate numbers.
const SPLIT_SEPARATE_NUMBER_RE = /(\d)\p{Ll}|(\p{L})\d/u

// Regexp involved with stripping non-word characters from the result.
const DEFAULT_STRIP_REGEXP = /[^\p{L}\d]+/giu

// The replacement value for splits.
const SPLIT_REPLACE_VALUE = '$1\0$2'

// The default characters to keep after transforming case.
const DEFAULT_PREFIX_SUFFIX_CHARACTERS = ''

/**
 * Split any cased input strings into an array of words.
 */
const split = (value: string) => {
  let result = value.trim()

  result = result
    .replace(SPLIT_LOWER_UPPER_RE, SPLIT_REPLACE_VALUE)
    .replace(SPLIT_UPPER_UPPER_RE, SPLIT_REPLACE_VALUE)

  result = result.replace(DEFAULT_STRIP_REGEXP, '\0')

  let start = 0
  let end = result.length

  // Trim the delimiter from around the output string.
  while (result.charAt(start) === '\0') start++
  if (start === end) return []
  while (result.charAt(end - 1) === '\0') end--

  return result.slice(start, end).split(/\0/g)
}

/**
 * Split the input string into an array of words, separating numbers.
 */
const splitSeparateNumbers = (value: string) => {
  const words = split(value)
  for (let i = 0; i < words.length; i++) {
    const word = words[i]
    const match = SPLIT_SEPARATE_NUMBER_RE.exec(word)
    if (match) {
      const offset =
        match.index + (match[1] !== undefined ? match[1] : match[2]).length
      words.splice(i, 1, word.slice(0, offset), word.slice(offset))
    }
  }
  return words
}

/**
 * Convert a string to space separated lower case (`foo bar`).
 */
const noCase = (input: string, options?: OptionsType) => {
  const { prefix, words, suffix } = splitPrefixSuffix(input, options)
  return (
    prefix +
    words
      .map(lowerFactory(options?.locale ?? false))
      .join(options?.delimiter ?? ' ') +
    suffix
  )
}

/**
 * Convert a string to camel case (`fooBar`).
 */
const camelCase = (input: string, options?: OptionsType) => {
  const { prefix, words, suffix } = splitPrefixSuffix(input, options)
  const lower = lowerFactory(options?.locale ?? false)
  const upper = upperFactory(options?.locale ?? false)
  const transform = options?.mergeAmbiguousCharacters
    ? capitalCaseTransformFactory(lower, upper)
    : pascalCaseTransformFactory(lower, upper)
  return (
    prefix +
    words
      .map((word: string, index: number) => {
        if (index === 0) return lower(word)
        return transform(word, index)
      })
      .join(options?.delimiter ?? '') +
    suffix
  )
}

/**
 * Convert a string to pascal case (`FooBar`).
 */
const pascalCase = (input: string, options?: OptionsType) => {
  const { prefix, words, suffix } = splitPrefixSuffix(input, options)
  const lower = lowerFactory(options?.locale ?? false)
  const upper = upperFactory(options?.locale ?? false)
  const transform = options?.mergeAmbiguousCharacters
    ? capitalCaseTransformFactory(lower, upper)
    : pascalCaseTransformFactory(lower, upper)
  return prefix + words.map(transform).join(options?.delimiter ?? '') + suffix
}

/**
 * Convert a string to pascal snake case (`Foo_Bar`).
 */
const pascalSnakeCase = (input: string, options: OptionsType) => {
  return capitalCase(input, { delimiter: '_', ...options })
}

/**
 * Convert a string to capital case (`Foo Bar`).
 */
const capitalCase = (input: string, options?: OptionsType) => {
  const { prefix, words, suffix } = splitPrefixSuffix(input, options)
  const lower = lowerFactory(options?.locale ?? false)
  const upper = upperFactory(options?.locale ?? false)
  return (
    prefix +
    words
      .map(capitalCaseTransformFactory(lower, upper))
      .join(options?.delimiter ?? ' ') +
    suffix
  )
}

/**
 * Convert a string to constant case (`FOO_BAR`).
 */
const constantCase = (input: string, options?: OptionsType) => {
  const { prefix, words, suffix } = splitPrefixSuffix(input, options)
  return (
    prefix +
    words
      .map(upperFactory(options?.locale ?? false))
      .join(options?.delimiter ?? '_') +
    suffix
  )
}

/**
 * Convert a string to dot case (`foo.bar`).
 */
const dotCase = (input: string, options?: OptionsType) => {
  return noCase(input, { delimiter: '.', ...options })
}

/**
 * Convert a string to kebab case (`foo-bar`).
 */
const kebabCase = (input: string, options?: OptionsType) => {
  return noCase(input, { delimiter: '-', ...options })
}

/**
 * Convert a string to path case (`foo/bar`).
 */
const pathCase = (input: string, options?: OptionsType) => {
  return noCase(input, { delimiter: '/', ...options })
}

/**
 * Convert a string to path case (`Foo bar`).
 */
const sentenceCase = (input: string, options?: OptionsType) => {
  const { prefix, words, suffix } = splitPrefixSuffix(input, options)
  const lower = lowerFactory(options?.locale ?? false)
  const upper = upperFactory(options?.locale ?? false)
  const transform = capitalCaseTransformFactory(lower, upper)
  return (
    prefix +
    words
      .map((word, index) => {
        if (index === 0) return transform(word)
        return lower(word)
      })
      .join(options?.delimiter ?? ' ') +
    suffix
  )
}

/**
 * Convert a string to snake case (`foo_bar`).
 */
const snakeCase = (input: string, options?: OptionsType) => {
  return noCase(input, { delimiter: '_', ...options })
}

/**
 * Convert a string to header case (`Foo-Bar`).
 */
const trainCase = (input: string, options?: OptionsType) => {
  return capitalCase(input, { delimiter: '-', ...options })
}

const lowerFactory = (locale: LocaleType) => {
  return locale === false
    ? (input: string) => input.toLowerCase()
    : (input: string) => input.toLocaleLowerCase(locale)
}

const upperFactory = (locale: LocaleType) => {
  return locale === false
    ? (input: string) => input.toUpperCase()
    : (input: string) => input.toLocaleUpperCase(locale)
}

const capitalCaseTransformFactory = (
  lower: LowerUpperFunctionType,
  upper: LowerUpperFunctionType
) => {
  return (word: string) => `${upper(word[0])}${lower(word.slice(1))}`
}

const pascalCaseTransformFactory = (
  lower: LowerUpperFunctionType,
  upper: LowerUpperFunctionType
) => {
  return (word: string, index: number) => {
    const char0 = word[0]
    const initial =
      index > 0 && char0 >= '0' && char0 <= '9' ? '_' + char0 : upper(char0)
    return initial + lower(word.slice(1))
  }
}

const splitPrefixSuffix = (input: string, options?: OptionsType) => {
  const splitFn = options?.separateNumbers ? splitSeparateNumbers : split
  const prefixCharacters =
    options?.prefixCharacters ?? DEFAULT_PREFIX_SUFFIX_CHARACTERS
  const suffixCharacters =
    options?.suffixCharacters ?? DEFAULT_PREFIX_SUFFIX_CHARACTERS
  let prefixIndex = 0
  let suffixIndex = input.length

  while (prefixIndex < input.length) {
    const char = input.charAt(prefixIndex)
    if (!prefixCharacters.includes(char)) break
    prefixIndex++
  }

  while (suffixIndex > prefixIndex) {
    const index = suffixIndex - 1
    const char = input.charAt(index)
    if (!suffixCharacters.includes(char)) break
    suffixIndex = index
  }

  const prefix = input.slice(0, prefixIndex)
  const words = splitFn(input.slice(prefixIndex, suffixIndex))
  const suffix = input.slice(suffixIndex)

  return { prefix, words, suffix }
}

export default {
  camelCase,
  capitalCase,
  constantCase,
  dotCase,
  trainCase,
  noCase,
  kebabCase,
  pascalCase,
  pathCase,
  sentenceCase,
  snakeCase,
  pascalSnakeCase,
}
