import { useEffect, useState } from 'react'

import { Autocomplete, Grid, TextField } from '@mui/material'
import { BasicDialog } from 'components'

import { useFetch, useSnackbar } from 'hooks'

import service from 'service'

import { QuestionnaireType, QuestionType } from 'types/questionnaire'
import {
  OptionType,
  QuestionImportDialogType,
} from './questionImportDialog.types'
import { isEmpty } from 'lodash'
import { formatters } from 'helpers'

const QuestionImportDialog = ({
  questionnaireId,
  open,
  onClose,
  reload,
}: QuestionImportDialogType) => {
  const { snackbar } = useSnackbar()

  const [loadingQuestions, setLoadingQuestions] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const [questionsTimeoutFn, setQuestionsTimeoutFn] = useState<NodeJS.Timer>()
  const [selectedQuestionnaires, setSelectedQuestionnaires] = useState<
    OptionType[]
  >([])
  const [selectedQuestions, setSelectedQuestions] = useState<OptionType[]>([])

  const [questionOptions, setQuestionOptions] = useState<OptionType[]>([])

  const { response, loading } = useFetch(
    service.dponet.questionnaires.get,
    {
      params: {},
    },
    [],
    open,
    true
  )

  const getQuestions = async () => {
    const response = await service.dponet.questionnaires.getQuestions({
      params: {
        perPage: 9999,
        minimal: true,
        quizTemplateIds: selectedQuestionnaires.map(
          (questionnaire) => questionnaire.id
        ),
      },
    })

    setQuestionOptions(
      response?.data?.quizQuestionTemplates?.map((question: QuestionType) => ({
        id: question.id,
        name: question.theme,
      })) || []
    )
    setLoadingQuestions(false)
  }

  useEffect(() => {
    if (questionsTimeoutFn) {
      clearTimeout(questionsTimeoutFn)
    }

    if (selectedQuestionnaires.length > 0) {
      setLoadingQuestions(true)
      setQuestionsTimeoutFn(setTimeout(getQuestions, 1500))
    }
  }, [selectedQuestionnaires])

  const questionnaireOptions: OptionType[] =
    (response?.data?.quizTemplates as QuestionnaireType[])?.map(
      (quizTemplate) => ({
        id: quizTemplate.id,
        name: quizTemplate.kind,
      })
    ) || []

  const handleSave = async () => {
    try {
      setSubmitted(true)
      await service.dponet.questionnaires.copyQuestions({
        quizTemplateId: questionnaireId,
        quizQuestionTemplateIds: selectedQuestions.map(
          (question) => question.id
        ),
      })
      reload()

      snackbar.open({
        variant: 'success',
        message: 'Questões importadas com sucesso.',
      })
      onClose()
    } catch (error) {
      snackbar.open({
        variant: 'error',
        message:
          formatters.errorMessage(error) ||
          'Ocorreu um erro ao importar as questões.',
      })
    } finally {
      setSubmitted(false)
    }
  }

  return (
    <BasicDialog
      open={open}
      maxWidth="lg"
      title="Importar questões"
      form=""
      saveButtonName="Importar"
      closeButtonName="Cancelar"
      disabled={isEmpty(selectedQuestions) || submitted}
      onClose={onClose}
      onSave={handleSave}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Autocomplete
            disabled={loadingQuestions || submitted}
            getOptionLabel={(option) => option.name || ''}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            loading={loading}
            multiple
            noOptionsText="Nenhum questionário encontrado"
            options={questionnaireOptions.filter(
              (questionnaire) => questionnaire.id !== questionnaireId
            )}
            onChange={(_, value) => setSelectedQuestionnaires(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Questionário"
                placeholder="Selecione os questionários que deseja importar as questões."
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            disabled={
              isEmpty(selectedQuestionnaires) || loadingQuestions || submitted
            }
            getOptionLabel={(option) => option.name || ''}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            loading={loading || loadingQuestions}
            multiple
            noOptionsText="Nenhuma questão encontrada"
            onChange={(_, value) => setSelectedQuestions(value)}
            options={questionOptions}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Questões"
                placeholder="Selecione as questões que deseja importar."
              />
            )}
          />
        </Grid>
      </Grid>
    </BasicDialog>
  )
}

export default QuestionImportDialog
