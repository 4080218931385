import { Box, CircularProgress } from '@mui/material'

import { useOrganization } from 'hooks'

import { OrganizationDependentRouteType } from './organizationDependentRoute.types'

const OrganizationDependentRoute = ({
  children,
}: OrganizationDependentRouteType) => {
  const { loadedOrganizations, currentOrganization } = useOrganization()

  if (loadedOrganizations && currentOrganization) return <>{children}</>

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100%"
    >
      <CircularProgress color="primary" />
    </Box>
  )
}

export default OrganizationDependentRoute
