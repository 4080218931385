import { Controller, useForm } from 'react-hook-form'
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'

import { useSnackbar } from 'hooks'

import { formatters } from 'helpers'

import { QuestionnairesCreateFormType } from './questionnairesCreateForm.types'

import schema, { QuestionnairesCreateFormSchemaType } from './schema'
import constants from 'constants/index'
import service from 'service'

const QuestionnairesCreateForm = ({
  formName,
  onClose,
  refresh,
  setIsLoading,
}: QuestionnairesCreateFormType) => {
  const { snackbar } = useSnackbar()
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<QuestionnairesCreateFormSchemaType>({
    resolver: yupResolver(schema),
    defaultValues: {
      kind: '',
      interaction: 'global',
    },
  })

  const onSubmit = async (data: QuestionnairesCreateFormSchemaType) => {
    try {
      setIsLoading(true)
      await service.dponet.questionnaires.post({ quizTemplate: { ...data } })
      snackbar.open({
        message: 'Questionário criado com sucesso!',
        variant: 'success',
      })
      reset()
      refresh()
      onClose()
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: formatters.errorMessage(error),
        variant: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      id={formName}
      display="flex"
      flexDirection="column"
      gap={4}
    >
      <Controller
        render={({ field }) => (
          <TextField
            {...field}
            error={!!errors?.kind}
            helperText={errors?.kind?.message}
            label="Título do questionário"
            type="text"
            variant="outlined"
            fullWidth
          />
        )}
        control={control}
        name="kind"
      />
      <Controller
        render={({ field }) => (
          <FormControl
            fullWidth
            margin="dense"
            error={!!errors.interaction?.message}
          >
            <InputLabel id="interaction-label">Destinação</InputLabel>
            <Select
              {...field}
              labelId="interaction-label"
              label="Selecione o tipo de pessoa"
            >
              {constants.questionnaire.SELECT_DESTINATION_ITEMS.map(
                (item, index) => (
                  <MenuItem key={index} value={item?.value}>
                    {item?.name}
                  </MenuItem>
                )
              )}
            </Select>
            {!!errors.interaction && (
              <FormHelperText>{errors.interaction?.message}</FormHelperText>
            )}
          </FormControl>
        )}
        control={control}
        name="interaction"
      />
    </Box>
  )
}

export default QuestionnairesCreateForm
