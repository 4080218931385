import { colors } from '@mui/material'

import { FormFieldType } from 'types/questionnaire'

import theme from 'theme'

const ACTIVE_QUESTION_STATUS = 'active'
const INACTIVE_QUESTION_STATUS = 'inactive'

enum QUESTIONNAIRE_ENUMS {
  ANSWERED = 'answered',
  AWAITING_RESPONSE = 'awaiting_response',
  PARTIALLY_ANSWERED = 'partially_answered',
}

enum INTERACTION_ENUMS {
  GLOBAL = 'global',
  DIAGNOSTIC = 'diagnostic',
  GOVERNANCE_PROJECT = 'governance_project',
}

const SELECT_DESTINATION_ITEMS = [
  { name: 'Todos', value: INTERACTION_ENUMS.GLOBAL },
  { name: 'Diagnóstico', value: INTERACTION_ENUMS.DIAGNOSTIC },
  {
    name: 'Projeto de Governança',
    value: INTERACTION_ENUMS.GOVERNANCE_PROJECT,
  },
]

const CONFORMITY_LEVEL_ONE = 1
const CONFORMITY_LEVEL_TWO_ = 2
const CONFORMITY_LEVEL_THREE = 3

const ACTIVE_QUESTIONNAIRE_LABEL = 'Ativo'
const INACTIVE_QUESTIONNAIRE_LABEL = 'Inativo'

const ACTIVE_QUESTION_LABEL = 'Ativo'
const INACTIVE_QUESTION_LABEL = 'Inativo'

const GOVERNANCE_PROJECT_INTERACTION_TYPES = [
  INTERACTION_ENUMS.GLOBAL as string,
  INTERACTION_ENUMS.GOVERNANCE_PROJECT as string,
]

const DIAGNOSTIC_INTERACTION_TYPES = [
  INTERACTION_ENUMS.GLOBAL as string,
  INTERACTION_ENUMS.DIAGNOSTIC as string,
]

const TRANSLATED_QUESTIONNAIRE_ENUMS: {
  [key: string]: string
} = {
  [QUESTIONNAIRE_ENUMS.ANSWERED]: 'Respondido',
  [QUESTIONNAIRE_ENUMS.AWAITING_RESPONSE]: 'Aguardando Resposta',
  [QUESTIONNAIRE_ENUMS.PARTIALLY_ANSWERED]: 'Parcialmente Respondido',
}

const TRANSLATED_INTERACTION_ENUMS: {
  [key: string]: string
} = {
  [INTERACTION_ENUMS.GLOBAL]: 'Global',
  [INTERACTION_ENUMS.DIAGNOSTIC]: 'Diagnóstico',
  [INTERACTION_ENUMS.GOVERNANCE_PROJECT]: 'Projeto de Governança',
}

const ALL_QUESTION_STATUS = [
  ACTIVE_QUESTION_STATUS,
  INACTIVE_QUESTION_STATUS,
] as const

const LEGAL_QUESTIONNAIRE = 'Jurídico'
const GOVERNANCE_AND_CULTURAL_QUESTIONNAIRE = 'Governança e Cultura'
const INFRASTRUCTURE_AND_TECHNOLOGY_QUESTIONNAIRE =
  'Infraestrutura e Tecnologia'
const FUNDAMENTAL_REQUIREMENTS_QUESTIONNAIRE = 'Requisitos Fundamentais'

const CONFORMITY_LEVELS = [
  { value: CONFORMITY_LEVEL_ONE, label: 'Nível 1' },
  { value: CONFORMITY_LEVEL_TWO_, label: 'Nível 2' },
  { value: CONFORMITY_LEVEL_THREE, label: 'Nível 3' },
]

const QUESTIONNAIRES_COLORS = {
  [LEGAL_QUESTIONNAIRE]: theme.palette.primary.main,
  [GOVERNANCE_AND_CULTURAL_QUESTIONNAIRE]: colors.lightBlue['A100'],
  [INFRASTRUCTURE_AND_TECHNOLOGY_QUESTIONNAIRE]: colors.red['A700'],
  [FUNDAMENTAL_REQUIREMENTS_QUESTIONNAIRE]: colors.green['A700'],
}

const QUESTIONNAIRES_STATUS_COLORS: {
  [key in string]: string
} = {
  [QUESTIONNAIRE_ENUMS.ANSWERED]: colors.green[500],
  [QUESTIONNAIRE_ENUMS.AWAITING_RESPONSE]: colors.blue[500],
  [QUESTIONNAIRE_ENUMS.PARTIALLY_ANSWERED]: colors.orange[500],
}

const QUESTION_CHIP_STATUS_TRANSLATION = {
  [ACTIVE_QUESTION_STATUS]: ACTIVE_QUESTION_LABEL,
  [INACTIVE_QUESTION_STATUS]: INACTIVE_QUESTION_LABEL,
}

const QUESTION_CHIP_STATUS_COLOR = {
  [ACTIVE_QUESTION_STATUS]: 'success',
  [INACTIVE_QUESTION_STATUS]: 'neutral',
}

const QUESTIONNAIRES = [
  LEGAL_QUESTIONNAIRE,
  GOVERNANCE_AND_CULTURAL_QUESTIONNAIRE,
  INFRASTRUCTURE_AND_TECHNOLOGY_QUESTIONNAIRE,
  FUNDAMENTAL_REQUIREMENTS_QUESTIONNAIRE,
] as const

export { QUESTIONNAIRES }

const QUESTION_FORM_FIELDS: FormFieldType[] = [
  { name: 'theme', label: 'Tema', multiline: false, rows: 1 },
  { name: 'question', label: 'Pergunta', multiline: true, rows: 4 },
  {
    name: 'explanation',
    label: 'Explicação',
    multiline: true,
    rows: 4,
  },
  {
    name: 'purpose',
    label: 'Propósito (objetivo)',
    multiline: true,
    rows: 4,
  },
  {
    name: 'securityPolicy',
    label:
      'Medida de Segurança (o conteúdo da pergunta, mas no modo afirmativo, para ser atualizado na geração de relatórios)',
    multiline: true,
    rows: 4,
  },
  {
    name: 'videoUrl',
    label: 'Link de vídeo (Vimeo)',
    multiline: false,
    rows: 1,
  },
]

export type ChipStatusQuestionnairesType = (typeof ALL_QUESTION_STATUS)[number]

export default {
  ACTIVE_QUESTION_LABEL,
  ACTIVE_QUESTION_STATUS,
  ACTIVE_QUESTIONNAIRE_LABEL,
  CONFORMITY_LEVEL_ONE,
  CONFORMITY_LEVEL_THREE,
  CONFORMITY_LEVEL_TWO_,
  CONFORMITY_LEVELS,
  FUNDAMENTAL_REQUIREMENTS_QUESTIONNAIRE,
  GOVERNANCE_AND_CULTURAL_QUESTIONNAIRE,
  INACTIVE_QUESTION_LABEL,
  INACTIVE_QUESTION_STATUS,
  INACTIVE_QUESTIONNAIRE_LABEL,
  INFRASTRUCTURE_AND_TECHNOLOGY_QUESTIONNAIRE,
  LEGAL_QUESTIONNAIRE,
  QUESTION_CHIP_STATUS_COLOR,
  QUESTION_CHIP_STATUS_TRANSLATION,
  QUESTION_FORM_FIELDS,
  QUESTIONNAIRES_COLORS,
  QUESTIONNAIRES_STATUS_COLORS,
  QUESTIONNAIRES,
  TRANSLATED_QUESTIONNAIRE_ENUMS,
  TRANSLATED_INTERACTION_ENUMS,
  GOVERNANCE_PROJECT_INTERACTION_TYPES,
  DIAGNOSTIC_INTERACTION_TYPES,
  SELECT_DESTINATION_ITEMS,
  INTERACTION_ENUMS,
}
