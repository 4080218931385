import { useState } from 'react'
import { Button, Typography } from '@mui/material'

import { BasicDialog, LoadingFeedback } from 'components'

import { useSnackbar, useOrganization } from 'hooks'
import organizationUsersConstants from 'constants/organizationUsers'
import service from 'service'
import { formatters } from 'helpers'

const InviteOrganization = () => {
  const [loading, setLoading] = useState(false)

  const { snackbar } = useSnackbar()
  const { organizations, loadOrganizations } = useOrganization()

  const pendingOrganization = organizations?.find(
    (organization) =>
      organization.organizationUser.status ===
      organizationUsersConstants.PENDING_STATUS
  )

  const changeInviteStatus = async (status: string) => {
    try {
      setLoading(true)
      if (pendingOrganization?.id) {
        await service.dponet.organizationUser.replyInvite({
          organizationId: pendingOrganization.id,
          status: status,
          organizationUserId: pendingOrganization.organizationUser.id,
        })

        snackbar.open({
          message: 'Convite atualizado com sucesso!',
          variant: 'success',
        })
      }

      await loadOrganizations()
    } catch (error) {
      console.error(error)
      await loadOrganizations()
      snackbar.open({
        message: formatters.errorMessage(error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <BasicDialog
      open
      title="Você foi convidado para participar deste grupo advisor"
      onClose={() => {}}
      maxWidth="md"
      dialogActions={
        <>
          <Button
            variant="contained"
            color="error"
            onClick={() =>
              changeInviteStatus(organizationUsersConstants.INACTIVE_STATUS)
            }
          >
            RECUSAR CONVITE
          </Button>
          <Button
            variant="contained"
            color="tertiary"
            onClick={() =>
              changeInviteStatus(organizationUsersConstants.ACTIVE_STATUS)
            }
          >
            ACEITAR CONVITE
          </Button>
        </>
      }
    >
      <LoadingFeedback open={loading} />
      <Typography variant="body1" fontWeight="bold" gutterBottom>
        Bem-vindo à DPO Advisor! Estamos empolgados por tê-lo(a) a bordo.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Para começar a utilizar todas as funcionalidades e recursos que nossa
        plataforma oferece, é necessário participar de um time de trabalho,
        chamado &quot;<strong>Grupo Advisor</strong>&quot;. Esse time será o
        ponto central das suas atividades e interações com outros colaboradores.
      </Typography>

      <Typography variant="body1" gutterBottom>
        Grupo Advisor: <strong>{pendingOrganization?.name}</strong>
      </Typography>
    </BasicDialog>
  )
}

export default InviteOrganization
