import { Controller, useForm } from 'react-hook-form'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  TextField,
  Typography,
} from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material'

import { useSnackbar } from 'hooks'

import { formatters } from 'helpers'

import { QuestionFormType } from './questionForm.types'

import schema, { QuestionFormSchemaType } from './schema'
import service from 'service'

const QuestionForm = ({
  setIsLoading,
  onClose,
  isSpecifyField = false,
  isEdit = false,
  specifyFieldId,
  companyId,
  answerId,
  refresh,
  question,
}: QuestionFormType) => {
  const { snackbar } = useSnackbar()
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<QuestionFormSchemaType>({
    resolver: yupResolver(schema),
    defaultValues: {
      title: question?.title || '',
      integrationKey: question?.integrationKey || '',
    },
  })

  const onSubmit = async (data: QuestionFormSchemaType) => {
    try {
      setIsLoading(true)

      if (isSpecifyField && isEdit) {
        await service.dponet.privacyPortal.updateSpecifyField({
          specifyFieldId,
          answerOptionTicket: data,
        })
      } else if (isSpecifyField && !isEdit) {
        await service.dponet.privacyPortal.createSpecifyField({
          answerId,
          answerOptionTicket: data,
        })
      } else if (!isSpecifyField && isEdit) {
        await service.dponet.privacyPortal.updateQuestion({
          questionId: question?.questionId,
          questionOptionTicket: data,
        })
      } else {
        await service.dponet.privacyPortal.createQuestion({
          companyId,
          answerId,
          questionOptionTicket: data,
        })
      }

      snackbar.open({
        message: isEdit
          ? 'Pergunta alterada com sucesso!'
          : 'Pergunta criada com sucesso!',
        variant: 'success',
      })
      refresh()
      onClose()
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: formatters.errorMessage(error),
        variant: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      id="privacy-portal-question-form"
      display="flex"
      flexDirection="column"
      gap={4}
    >
      <Controller
        render={({ field }) => (
          <TextField
            {...field}
            error={!!errors?.title}
            helperText={errors?.title?.message}
            label="Título da pergunta"
            type="text"
            variant="outlined"
            fullWidth
          />
        )}
        control={control}
        name="title"
      />
      <Accordion elevation={0}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography component="span">Configurações Avançadas</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors?.integrationKey}
                helperText={errors?.integrationKey?.message}
                label="Chave de Integração"
                type="text"
                variant="outlined"
                fullWidth
              />
            )}
            control={control}
            name="integrationKey"
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default QuestionForm
