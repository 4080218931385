import { Controller, useForm } from 'react-hook-form'
import { Info as InfoIcon } from 'react-feather'
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  Tooltip,
} from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'

import { MUI, PaperHeader, Permitted } from 'components'

import { useSnackbar } from 'hooks'

import { formatters } from 'helpers'

import { GeneralInformationCardType } from './generalInformationCard.types'

import schema, { GeneralInformationCardSchemaType } from './schema'
import constants from 'constants/index'
import service from 'service'

const GeneralInformationCard = ({
  disableManagement = false,
  questionnaire,
  questionnaireId,
  refreshQuestionnaire,
  setIsLoading,
}: GeneralInformationCardType) => {
  const { INTERACTION_ENUMS, SELECT_DESTINATION_ITEMS } =
    constants.questionnaire

  const { snackbar } = useSnackbar()
  const defaultVisible =
    questionnaire.governanceProjectsCount > 0 ||
    questionnaire.vinculatedCompaniesCount > 0

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<GeneralInformationCardSchemaType>({
    resolver: yupResolver(schema),
    defaultValues: {
      kind: questionnaire?.kind || '',
      visible: questionnaire?.default
        ? defaultVisible
        : questionnaire?.visible || false,
      interaction: questionnaire?.interaction || 'global',
    },
  })

  const interaction = watch('interaction')

  const isGovernanceProject =
    interaction === INTERACTION_ENUMS.GOVERNANCE_PROJECT

  const onSubmit = async (data: GeneralInformationCardSchemaType) => {
    try {
      setIsLoading(true)

      await service.dponet.questionnaires.update({
        quizTemplateId: questionnaireId,
        quizTemplate: { ...data },
      })

      snackbar.open({
        message: 'Informações gerais alterada com sucesso!',
        variant: 'success',
      })
      refreshQuestionnaire()
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: formatters.errorMessage(error),
        variant: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Paper
      variant="outlined"
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      id="general-information-questionnaires-form"
    >
      <PaperHeader title="Informações Gerais" px={3} />
      <Box p={3} display="flex" flexDirection="column" gap={4}>
        <Controller
          render={({ field }) => (
            <FormControl component={Box} width="fit-content">
              <Box display="flex" flexDirection="row">
                <MUI.FormControlLabel
                  control={
                    <Switch
                      {...field}
                      checked={!!field.value}
                      disabled={disableManagement || isGovernanceProject}
                    />
                  }
                  label="Ativar questionário"
                  $marginRight={0}
                />
                <Box>
                  <Tooltip
                    title={
                      isGovernanceProject
                        ? 'Os questionários de projeto de governança não precisam ser ativados'
                        : 'Para ativar um questionário, é necessário que haja uma questão previamente definida'
                    }
                  >
                    <IconButton size="small">
                      <InfoIcon size={12} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
              {!!errors?.visible && (
                <FormHelperText error={!!errors?.visible}>
                  {errors?.visible?.message}
                </FormHelperText>
              )}
            </FormControl>
          )}
          control={control}
          name="visible"
        />
        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors?.kind}
              helperText={errors?.kind?.message}
              disabled={disableManagement}
              label="Título do questionário"
              type="text"
              variant="outlined"
              fullWidth
            />
          )}
          control={control}
          name="kind"
        />
        <Controller
          render={({ field }) => (
            <FormControl
              fullWidth
              margin="dense"
              error={!!errors.interaction?.message}
            >
              <InputLabel id="interaction-label">Destinação</InputLabel>
              <Select
                {...field}
                labelId="interaction-label"
                label="Selecione o tipo de pessoa"
              >
                {SELECT_DESTINATION_ITEMS.map((item, index) => (
                  <MenuItem key={index} value={item?.value}>
                    {item?.name}
                  </MenuItem>
                ))}
              </Select>
              {!!errors.interaction && (
                <FormHelperText>{errors.interaction?.message}</FormHelperText>
              )}
            </FormControl>
          )}
          control={control}
          name="interaction"
        />
        <Box display="flex" justifyContent="end">
          <Permitted tag={constants.permissionTags.QUESTIONNAIRES.MANAGE}>
            <Button
              variant="contained"
              form="general-information-questionnaires-form"
              type="submit"
              disabled={disableManagement}
            >
              Salvar
            </Button>
          </Permitted>
        </Box>
      </Box>
    </Paper>
  )
}

export default GeneralInformationCard
