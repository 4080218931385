import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({ params = {} }: { params?: object }) => {
  return dponetAPI.get(`/advisors/organization_quiz_templates/`, {
    params,
  })
}

const show = async ({
  quizTemplateId = '',
  params = {},
}: {
  quizTemplateId?: number | string
  params?: object
}) => {
  return dponetAPI.get(
    `/advisors/organization_quiz_templates/${quizTemplateId}`,
    {
      params,
    }
  )
}

const post = async ({ quizTemplate = {} }: { quizTemplate?: object }) => {
  return dponetAPI.post(`/advisors/organization_quiz_templates`, {
    quizTemplate,
  })
}

const update = async ({
  quizTemplateId = '',
  quizTemplate = {},
}: {
  quizTemplateId?: number | string
  quizTemplate?: object
}) => {
  return dponetAPI.put(
    `/advisors/organization_quiz_templates/${quizTemplateId}`,
    {
      quizTemplate,
    }
  )
}

const linkCompanies = async ({
  quizTemplateId = '',
  quizTemplate = {},
}: {
  quizTemplateId?: number | string
  quizTemplate?: object
}) => {
  return dponetAPI.patch(
    `/advisors/organization_quiz_templates/${quizTemplateId}/vinculate_companies`,
    {
      quizTemplate,
    }
  )
}

const uncoupleCompanies = async ({
  quizTemplateId = '',
  quizTemplate = {},
}: {
  quizTemplateId?: number | string
  quizTemplate?: object
}) => {
  return dponetAPI.patch(
    `/advisors/organization_quiz_templates/${quizTemplateId}/unvinculate_company`,
    {
      quizTemplate,
    }
  )
}

const getQuestions = async ({
  quizTemplateId = '',
  params = {},
}: {
  quizTemplateId?: number | string
  params?: object
}) => {
  const routePrefix = quizTemplateId
    ? `/organization_quiz_templates/${quizTemplateId}`
    : ''
  return dponetAPI.get(
    `/advisors${routePrefix}/organization_quiz_question_templates`,
    {
      params,
    }
  )
}

const createQuestion = async ({
  quizTemplateId = '',
  quizQuestion = {},
}: {
  quizTemplateId?: number | string
  quizQuestion?: object
}) => {
  return dponetAPI.post(
    `/advisors/organization_quiz_templates/${quizTemplateId}/organization_quiz_question_templates`,
    {
      quizQuestion,
    }
  )
}

const updateQuestion = async ({
  quizTemplateId = '',
  quizQuestionId = '',
  quizQuestion = {},
}: {
  quizTemplateId?: number | string
  quizQuestionId?: number | string
  quizQuestion?: object
}) => {
  return dponetAPI.put(
    `/advisors/organization_quiz_templates/${quizTemplateId}/organization_quiz_question_templates/${quizQuestionId}`,
    {
      quizQuestion,
    }
  )
}

const showQuestion = async ({
  quizTemplateId = '',
  quizQuestionId = '',
  params = {},
}: {
  quizTemplateId?: number | string
  quizQuestionId?: number | string
  params?: object
}) => {
  return dponetAPI.get(
    `/advisors/organization_quiz_templates/${quizTemplateId}/organization_quiz_question_templates/${quizQuestionId}`,
    {
      params,
    }
  )
}

const copyQuestions = async ({
  quizTemplateId = '',
  quizQuestionTemplateIds = [],
}: {
  quizTemplateId?: number | string
  quizQuestionTemplateIds?: number[] | string[]
}) => {
  return dponetAPI.post(
    `/advisors/organization_quiz_templates/${quizTemplateId}/copy_questions`,
    { quizQuestionTemplateIds }
  )
}

const inactiveQuestion = async ({
  quizTemplateId = '',
  quizQuestionId = '',
  quizQuestion = {},
}: {
  quizTemplateId?: number | string
  quizQuestionId?: number | string
  quizQuestion?: object
}) => {
  return dponetAPI.put(
    `/advisors/organization_quiz_templates/${quizTemplateId}/organization_quiz_question_templates/${quizQuestionId}/inactivate`,
    {
      quizQuestion,
    }
  )
}

const getVinculatedCompanies = async ({
  quizTemplateId = '',
  params = {},
}: {
  quizTemplateId?: number | string
  params?: object
}) => {
  return dponetAPI.get(
    `/advisors/organization_quiz_templates/${quizTemplateId}/list_vinculated_companies`,
    {
      params,
    }
  )
}

const getVinculatedProjects = async ({
  quizTemplateId = '',
  params = {},
}: {
  quizTemplateId?: number | string
  params?: object
}) => {
  return dponetAPI.get(
    `/advisors/organization_quiz_templates/${quizTemplateId}/list_vinculated_projects`,
    {
      params,
    }
  )
}

const getLogs = async ({
  quizTemplateId,
}: {
  quizTemplateId?: number | string
}) => {
  return dponetAPI.get(
    `/advisors/organization_quiz_templates/${quizTemplateId}/logs/`
  )
}

export default {
  createQuestion,
  get,
  getLogs,
  getQuestions,
  getVinculatedCompanies,
  getVinculatedProjects,
  copyQuestions,
  inactiveQuestion,
  linkCompanies,
  post,
  show,
  showQuestion,
  uncoupleCompanies,
  update,
  updateQuestion,
}
