import * as yup from 'yup'

const schema = yup.object({
  title: yup.string().max(250).required(),
  integrationKey: yup.string().max(35),
})

export type QuestionFormSchemaType = yup.InferType<typeof schema>

export default schema
