import api from 'service/api'

const dponetAPI = api.create('dponet', false)

const create = async ({
  quizQuestionTemplateId = '',
  params = {},
}: {
  quizQuestionTemplateId?: string | number
  params?: object
}) => {
  return await dponetAPI.postForm(
    `/advisors/organization_quiz_question_templates/${quizQuestionTemplateId}/evidence_plan_actions`,
    params
  )
}

const update = async ({
  quizQuestionTemplateId = '',
  evidencePlanActionId = '',
  params = {},
}: {
  quizQuestionTemplateId?: string | number
  evidencePlanActionId?: string | number
  params?: object
}) => {
  return await dponetAPI.putForm(
    `/advisors/organization_quiz_question_templates/${quizQuestionTemplateId}/evidence_plan_actions/${evidencePlanActionId}`,
    params
  )
}

const destroy = async ({
  quizQuestionTemplateId = '',
  evidencePlanActionId = '',
}: {
  quizQuestionTemplateId?: string | number
  evidencePlanActionId?: string | number
}) => {
  return await dponetAPI.delete(
    `/advisors/organization_quiz_question_templates/${quizQuestionTemplateId}/evidence_plan_actions/${evidencePlanActionId}`
  )
}

const destroyPlanAction = async ({
  quizQuestionTemplateId = '',
  evidencePlanActionId = '',
  planActionid = '',
}: {
  quizQuestionTemplateId?: string | number
  evidencePlanActionId?: string | number
  planActionid?: string | number
}) => {
  return await dponetAPI.delete(
    `/advisors/organization_quiz_question_templates/${quizQuestionTemplateId}/evidence_plan_actions/${evidencePlanActionId}/plan_actions/${planActionid}`
  )
}

const destroyDocument = async ({
  quizQuestionTemplateId = '',
  evidencePlanActionId = '',
  documentId = '',
}: {
  quizQuestionTemplateId?: string | number
  evidencePlanActionId?: string | number
  documentId?: string | number
}) => {
  return await dponetAPI.delete(
    `/advisors/organization_quiz_question_templates/${quizQuestionTemplateId}/evidence_plan_actions/${evidencePlanActionId}//evidences/destroy_document/${documentId}`
  )
}

export default {
  create,
  destroy,
  destroyPlanAction,
  destroyDocument,
  update,
}
