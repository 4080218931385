import { Fragment, useState } from 'react'
import { Divider, Grid } from '@mui/material'

import { EvidencePlanActionFormType } from './evidencePlanActionForm.types'
import { EvidenceFormSection, PlanActionFormSection } from '../'

const EvidencePlanActionForm = ({
  evidencePlanAction,
  evidencePlanActions,
  handleDestroyRegister,
  position,
  quizQuestionTemplateId,
  setEvidencePlanActions,
  setRegisterName,
}: EvidencePlanActionFormType) => {
  const [open, setOpen] = useState(!evidencePlanAction?.id)

  return (
    <Fragment>
      <Grid container spacing={4}>
        <EvidenceFormSection
          key={position}
          open={open}
          setOpen={setOpen}
          evidencePlanActions={evidencePlanActions}
          setEvidencePlanActions={setEvidencePlanActions}
          evidencePlanActionId={evidencePlanAction?.id}
          quizQuestionTemplateId={quizQuestionTemplateId}
          handleDestroyRegister={handleDestroyRegister}
          setRegisterName={() => setRegisterName('esta evidência')}
          position={position}
        />
        <PlanActionFormSection
          open={open}
          evidencePlanActionId={evidencePlanAction?.id}
          quizQuestionTemplateId={quizQuestionTemplateId}
          planActions={evidencePlanAction.planActions}
          positionEvidence={position}
          handleDestroyRegister={handleDestroyRegister}
          setRegisterName={() => setRegisterName('este plano de ação')}
        />
      </Grid>
      <Divider />
    </Fragment>
  )
}

export default EvidencePlanActionForm
