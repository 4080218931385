import { ChangeEvent, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material'

import { LoadingFeedback, PaperHeader } from 'components'

import { useSnackbar, useFetch } from 'hooks'

import { AnswerCardType } from './answerCard.types'

import { formatters, regex, validates } from 'helpers'
import schema, { AnswerCardSchemaType } from './schema'
import constants from 'constants/index'
import service from 'service'
import { ProfileType } from 'types/profile.types'

const AnswerCard = ({
  companyId,
  answerOptionTickets,
  disableSwitch,
  questionKind,
  setDisableSwitch,
  setOpenEditQuestionModal,
  setOptionsMenuType,
}: AnswerCardType) => {
  const demandQuestionKind =
    constants.privacyPortalForm.DEMAND_KIND === questionKind
  const [isLoading, setIsLoading] = useState(false)

  const { snackbar } = useSnackbar()
  const { answerId } = useParams()
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<AnswerCardSchemaType>({
    resolver: yupResolver(schema),
    defaultValues: {
      questionKind: questionKind,
      title: answerOptionTickets?.title || '',
      status: validates.privacyPortal.statusTranslationToSwitch(
        answerOptionTickets?.status || 'inactive'
      ),
      suboptions: answerOptionTickets?.subOption || false,
      specifications: answerOptionTickets?.specificField || false,
      deadlineDay: answerOptionTickets?.deadlineDay || undefined,
      companyDeadlineDay: answerOptionTickets?.companyDeadlineDay || undefined,
      responsibleProfileId: String(
        answerOptionTickets?.responsibleProfileId || ''
      ),
      coResponsibleProfileEnabled:
        answerOptionTickets?.coResponsibleProfileEnabled || false,
      coResponsibleProfileId: String(
        answerOptionTickets?.coResponsibleProfileId || ''
      ),
      automaticAnswerEnabled:
        answerOptionTickets?.automaticAnswerEnabled || false,
      automaticAnswer: answerOptionTickets?.automaticAnswer || '',
      integrationValue: answerOptionTickets?.integrationValue || '',
    },
  })
  const { response, loading } = useFetch(service.dponet.company.profiles, {
    companyId,
  })

  const suboption = !!answerOptionTickets?.childrenQuestionOptionTicket?.title
  const specification = !!answerOptionTickets?.textFieldTicket?.title
  const profiles: ProfileType[] = response?.data?.profiles

  const suboptionsSwitch = watch('suboptions')
  const specificationsSwitch = watch('specifications')
  const automaticAnswerEnabledSwitch = watch('automaticAnswerEnabled')
  const coResponsibleProfileEnabledSwitch = watch('coResponsibleProfileEnabled')

  const handleOpenSuboptionsEditQuestionModal = () => {
    if (!suboption) setOpenEditQuestionModal(true)
    else setOpenEditQuestionModal(false)
  }

  const handleOpenspecificationEditQuestionModal = () => {
    if (!specification) setOpenEditQuestionModal(true)
    else setOpenEditQuestionModal(false)
  }

  const onSubmit = async (data: AnswerCardSchemaType) => {
    try {
      setIsLoading(true)

      await service.dponet.privacyPortal.updateAnswer({
        answerId,
        answerOptionTicket: {
          title: data?.title,
          status: validates.privacyPortal.switchTranslation(data?.status),
          subOption: data?.suboptions,
          specificField: data?.specifications,
          deadlineDay: data?.deadlineDay,
          companyDeadlineDay: data?.companyDeadlineDay,
          responsibleProfileId: data?.responsibleProfileId,
          coResponsibleProfileEnabled: data?.coResponsibleProfileEnabled,
          coResponsibleProfileId: data?.coResponsibleProfileId,
          automaticAnswerEnabled: data?.automaticAnswerEnabled,
          automaticAnswer: data?.automaticAnswer,
          integrationValue: data?.integrationValue,
        },
      })

      snackbar.open({
        message: 'Resposta salva com sucesso!',
        variant: 'success',
      })
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: formatters.errorMessage(error),
        variant: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleChangeDeadLineDay = (
    onChange: (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void
  ) => {
    return (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      event.target.value = regex.onlyNumbers(event?.target?.value)
      onChange(event)
    }
  }

  useEffect(() => {
    if (suboptionsSwitch) setOptionsMenuType('suboption')
    else if (specificationsSwitch) setOptionsMenuType('specify')
    else setOptionsMenuType(undefined)
  }, [suboptionsSwitch, specificationsSwitch])

  useEffect(() => {
    if (disableSwitch) {
      setValue('suboptions', false)
      setValue('specifications', false)
      setDisableSwitch(false)
    }
  }, [disableSwitch])

  return (
    <Paper
      component={'form'}
      id="privacy-portal-answer-card"
      onSubmit={handleSubmit(onSubmit)}
    >
      <LoadingFeedback open={isLoading} />
      <PaperHeader
        title="Resposta"
        pt={2}
        px={5}
        actionButton={
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            id="privacy-portal-answer-card"
            disabled={isLoading}
          >
            Salvar
          </Button>
        }
      />
      <Box p={5} display="flex" flexDirection="column" gap={2}>
        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              type="text"
              label="Título da resposta"
              error={!!errors?.title}
              helperText={errors?.title?.message}
              fullWidth
            />
          )}
          control={control}
          name="title"
        />
        <Box display="flex" alignItems="center">
          <Controller
            render={({ field }) => (
              <FormControl>
                <FormControlLabel
                  control={<Switch {...field} checked={!!field.value} />}
                  label="Ativar"
                />
                {!!errors?.status && (
                  <FormHelperText error={!!errors?.status}>
                    {errors?.status?.message}
                  </FormHelperText>
                )}
              </FormControl>
            )}
            control={control}
            name="status"
          />
        </Box>
        <Box display="flex" alignItems="center">
          <Controller
            render={({ field }) => (
              <FormControl>
                <FormControlLabel
                  control={
                    <Switch
                      {...field}
                      checked={!!field.value}
                      onClick={handleOpenSuboptionsEditQuestionModal}
                      disabled={specificationsSwitch}
                    />
                  }
                  label={
                    <Typography>
                      Permitir inserir <strong>subopções</strong>&nbsp;
                      <sup>(Múltipla escolha)</sup>
                    </Typography>
                  }
                />
                {!!errors?.suboptions && (
                  <FormHelperText error={!!errors?.suboptions}>
                    {errors?.suboptions?.message}
                  </FormHelperText>
                )}
              </FormControl>
            )}
            control={control}
            name="suboptions"
          />
        </Box>
        <Box display="flex" alignItems="center">
          <Controller
            render={({ field }) => (
              <FormControl>
                <FormControlLabel
                  control={
                    <Switch
                      {...field}
                      checked={!!field.value}
                      onClick={handleOpenspecificationEditQuestionModal}
                      disabled={suboptionsSwitch}
                    />
                  }
                  label={
                    <Typography>
                      Permitir que <strong>especifique</strong> a resposta&nbsp;
                      <sup>(Campo texto)</sup>
                    </Typography>
                  }
                />
                {!!errors?.specifications && (
                  <FormHelperText error={!!errors?.specifications}>
                    {errors?.specifications?.message}
                  </FormHelperText>
                )}
              </FormControl>
            )}
            control={control}
            name="specifications"
          />
        </Box>

        {demandQuestionKind && (
          <>
            <Box display="flex" mt={3}>
              <Controller
                render={({ field: { onChange, ...field } }) => (
                  <TextField
                    {...field}
                    label="Prazo do Advisor"
                    error={!!errors?.deadlineDay}
                    helperText={errors?.deadlineDay?.message}
                    inputProps={{ maxLength: 2 }}
                    onChange={handleChangeDeadLineDay(onChange)}
                    fullWidth
                  />
                )}
                control={control}
                name="deadlineDay"
              />
            </Box>

            <Box display="flex" mt={3}>
              <Controller
                render={({ field: { onChange, ...field } }) => (
                  <TextField
                    {...field}
                    label="Prazo da Empresa"
                    error={!!errors?.companyDeadlineDay}
                    helperText={errors?.companyDeadlineDay?.message}
                    inputProps={{ maxLength: 2 }}
                    onChange={handleChangeDeadLineDay(onChange)}
                    fullWidth
                  />
                )}
                control={control}
                name="companyDeadlineDay"
              />
            </Box>

            <Box mt={3} mb={2}>
              <Alert severity="info" icon={false}>
                <AlertTitle>
                  Encaminhar para o responsável da organização
                </AlertTitle>
                Indique qual o responsável por validar a identidade do titular
                de dados e enviar as informações para possibilitar o
                atendimento.
              </Alert>
            </Box>

            {!loading ? (
              <Controller
                name="responsibleProfileId"
                control={control}
                render={({ field }) => (
                  <FormControl
                    fullWidth
                    margin="dense"
                    error={!!errors.responsibleProfileId?.message}
                  >
                    <InputLabel id="responsible-profile-id-select-label">
                      Responsável
                    </InputLabel>
                    <Select
                      {...field}
                      labelId="responsible-profile-id-select-label"
                      id="responsible-profile-id-select"
                      label="Vincular Grupo"
                    >
                      {profiles?.map((profile) => (
                        <MenuItem key={profile.id} value={profile.id}>
                          {profile.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {errors.responsibleProfileId?.message}
                    </FormHelperText>
                  </FormControl>
                )}
              />
            ) : (
              <Skeleton animation="wave" height={70} />
            )}

            <Controller
              render={({ field }) => (
                <FormControl>
                  <FormControlLabel
                    control={<Switch {...field} checked={!!field.value} />}
                    label="Ativar co-responsável"
                  />
                  {!!errors?.coResponsibleProfileEnabled && (
                    <FormHelperText
                      error={!!errors?.coResponsibleProfileEnabled}
                    >
                      {errors?.coResponsibleProfileEnabled?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
              control={control}
              name="coResponsibleProfileEnabled"
            />

            {coResponsibleProfileEnabledSwitch &&
              (loading ? (
                <Skeleton animation="wave" height={70} />
              ) : (
                <Controller
                  name="coResponsibleProfileId"
                  control={control}
                  render={({ field }) => (
                    <FormControl
                      fullWidth
                      margin="dense"
                      error={!!errors.coResponsibleProfileId?.message}
                    >
                      <InputLabel id="co-responsible-profile-id-select-label">
                        Co-responsável
                      </InputLabel>
                      <Select
                        {...field}
                        labelId="co-responsible-profile-id-select-label"
                        id="co-responsible-profile-id-select"
                        label="Vincular Grupo"
                      >
                        {profiles?.map((profile) => (
                          <MenuItem key={profile.id} value={profile.id}>
                            {profile.name}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {errors.coResponsibleProfileId?.message}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              ))}

            <Box mt={3}>
              <Alert severity="info" icon={false}>
                <AlertTitle>
                  Resposta automática após validação da identidade do titular
                </AlertTitle>
                Ao ativar esta opção, será enviada ao titular de dados uma
                resposta automática sem que haja a necessidade de receber as
                informações da organização para responder à requisição.
              </Alert>
            </Box>

            <Controller
              render={({ field }) => (
                <FormControl>
                  <FormControlLabel
                    control={<Switch {...field} checked={!!field.value} />}
                    label="Enviar resposta automática após validação da identidade"
                  />
                  {!!errors?.automaticAnswerEnabled && (
                    <FormHelperText error={!!errors?.automaticAnswerEnabled}>
                      {errors?.automaticAnswerEnabled?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
              control={control}
              name="automaticAnswerEnabled"
            />

            {automaticAnswerEnabledSwitch && (
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Resposta automática"
                    error={!!errors?.automaticAnswer}
                    helperText={errors?.automaticAnswer?.message}
                    multiline
                    rows={4}
                    fullWidth
                  />
                )}
                control={control}
                name="automaticAnswer"
              />
            )}
          </>
        )}

        <Box mt={3}>
          <Accordion elevation={0}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography component="span">Configurações Avançadas</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors?.integrationValue}
                    helperText={errors?.integrationValue?.message}
                    label="Valor Personalizado (para integração)"
                    type="text"
                    variant="outlined"
                    fullWidth
                  />
                )}
                control={control}
                name="integrationValue"
              />
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    </Paper>
  )
}

export default AnswerCard
