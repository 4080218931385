import autoCompleteNoText from './autoCompleteNoText'
import autoCompleteOptions from './autoCompleteOptions'
import cnpj from './cnpj'
import convertBoolToString from './convertBoolToString'
import cpf from './cpf'
import date from './date'
import dateFromNow from './dateFromNow'
import errorMessage from './errorMessage'
import file from './file'
import formatDataTomticket from './formatDataTomtickets'
import fullName from './fullName'
import identificationDocument from './identificationDocument'
import money from './money'
import phoneDynamicMask from './phoneDynamicMask'

export default {
  autoCompleteNoText,
  autoCompleteOptions,
  cnpj,
  convertBoolToString,
  cpf,
  date,
  dateFromNow,
  errorMessage,
  file,
  formatDataTomticket,
  fullName,
  identificationDocument,
  money,
  phoneDynamicMask,
}
