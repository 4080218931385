import { Fragment, useState } from 'react'
import { Typography } from '@mui/material'

import { BasicDialog, LoadingFeedback } from 'components'

import { useSnackbar } from 'hooks'
import { ConfirmDestroyType } from './ConfirmDestroy.types'
import { formatters } from 'helpers'

const ConfirmDestroy = ({
  open,
  onClose,
  handleDestroy,
  registerName = 'registro',
}: ConfirmDestroyType) => {
  const { snackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)

  const destroyTemplate = async () => {
    if (!handleDestroy) return

    setLoading(true)
    try {
      await handleDestroy()
      onClose()
    } catch (error) {
      snackbar.open({
        message: formatters.errorMessage(error, false),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Fragment>
      <LoadingFeedback open={loading} />
      <BasicDialog
        open={open}
        onClose={onClose}
        onSave={destroyTemplate}
        maxWidth="lg"
        title={`Tem certeza que deseja excluir ${registerName}?`}
        saveButtonName="Excluir"
        saveButtonColor="error"
      >
        <Typography variant="body1" color="text.secondary">
          Caso haja informações preenchidas, elas serão perdidas permanentemente
          e não poderão ser recuperadas.
        </Typography>
      </BasicDialog>
    </Fragment>
  )
}

export default ConfirmDestroy
