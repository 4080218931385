import { ChangeCaseFunctionType, OptionsType } from './changeCase.types'
import methods from './methods'
import { AxiosError, AxiosResponse } from 'axios'

type objectOfString = {
  [propKey: string]: object
}

const isObject = (object?: object | null) =>
  object !== null && typeof object === 'object'

const isFile = (object?: object) =>
  object instanceof File || object instanceof Blob || object instanceof FileList

function changeKeysFactory(changeCase: ChangeCaseFunctionType) {
  return function changeKeys(
    object: AxiosResponse | AxiosError | object,
    options?: OptionsType
  ): object {
    if (isFile(object) || !isObject(object)) return object

    if (Array.isArray(object)) {
      return object.map((item) => changeKeys(item, options))
    }

    const result = Object.create(Object.getPrototypeOf(object))

    Object.keys(object).forEach((key) => {
      const value = (object as objectOfString)[key]
      const changedKey = changeCase(key, options)
      const changedValue = changeKeys(value, options)
      result[changedKey] = changedValue
    })

    return result
  }
}

const camelCase = changeKeysFactory(methods.camelCase)
const capitalCase = changeKeysFactory(methods.capitalCase)
const constantCase = changeKeysFactory(methods.constantCase)
const dotCase = changeKeysFactory(methods.dotCase)
const trainCase = changeKeysFactory(methods.trainCase)
const noCase = changeKeysFactory(methods.noCase)
const kebabCase = changeKeysFactory(methods.kebabCase)
const pascalCase = changeKeysFactory(methods.pascalCase)
const pathCase = changeKeysFactory(methods.pathCase)
const sentenceCase = changeKeysFactory(methods.sentenceCase)
const snakeCase = changeKeysFactory(methods.snakeCase)

export default {
  camelCase,
  capitalCase,
  constantCase,
  dotCase,
  trainCase,
  noCase,
  kebabCase,
  pascalCase,
  pathCase,
  sentenceCase,
  snakeCase,
}
