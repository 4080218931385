import React, { useRef, useState } from 'react'
import {
  Upload as UploadIcon,
  Download as DownloadIcon,
  Trash2 as TrashIcon,
} from 'react-feather'
import {
  Box,
  Grid,
  Typography,
  Button,
  Link,
  FormHelperText,
  FormLabel,
} from '@mui/material'

import { MUI } from 'components'

import theme from 'theme'
import { isEmpty } from 'lodash'
import { Controller, useFormContext } from 'react-hook-form'

import { DocumentAttachmentType } from './DocumentAttachment.types'

import { QuestionFormSchemaType } from '../../schema'

const DocumentAttachment = ({
  position,
  selectedFile,
  setSelectedFile,
  attachment,
  name,
  description,
  error = false,
  deleteDocument,
}: DocumentAttachmentType) => {
  const { control, setValue } = useFormContext<QuestionFormSchemaType>()

  const fileInputRef = useRef<HTMLInputElement | null>(null)

  const [document, setDocument] = useState(attachment)

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files) {
      setSelectedFile(Array.from(event?.target?.files))
      setValue(
        `evidencePlanActions.${position}.evidence.attachments`,
        Array.from(event?.target?.files)
      )
    }
  }

  const handleRemoveFile = () => {
    if (setSelectedFile && selectedFile) {
      setSelectedFile(null)
      setDocument(undefined)

      setValue(`evidencePlanActions.${position}.evidence.attachments`, null)
      if (fileInputRef.current) {
        fileInputRef.current.value = ''
      }
    }
  }

  const callbackRemove = (fileId: number) => () => {
    setDocument((prevState) => {
      return prevState?.filter((file) => file?.id !== fileId)
    })
  }

  return (
    <Grid item xs={12}>
      <FormLabel>Documentos</FormLabel>
      <Box py={2}>
        <Typography variant="caption" color="GrayText">
          Documentos referências de evidências
        </Typography>
      </Box>
      <Box
        mt={2}
        padding={5}
        border="2px dashed #546E7A"
        borderRadius={2}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="100%"
      >
        {document && !isEmpty(document) ? (
          <>
            {document.map((file, index) => (
              <Box key={index} display="flex" alignItems="center" gap={1}>
                <DownloadIcon color={theme.palette.neutral.main} size={16} />
                <Typography
                  variant="body1"
                  pt={2}
                  color={theme.palette.neutral.main}
                >
                  <Link
                    href={file?.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="body1"
                    color={theme.palette.neutral.main}
                  >
                    {file?.name}
                  </Link>
                </Typography>
                <TrashIcon
                  color={theme.palette.neutral.main}
                  size={16}
                  onClick={() =>
                    deleteDocument(file?.id, callbackRemove(file?.id))
                  }
                  cursor="pointer"
                />
              </Box>
            ))}
          </>
        ) : (
          <>
            <MUI.LabelHtml
              htmlFor={name}
              $display="flex"
              $flexDirection="column"
              $alignItems="center"
              $cursor="pointer"
            >
              <UploadIcon color={theme.palette.neutral.main} />
              <Typography
                variant="body1"
                pt={2}
                color={theme.palette.neutral.main}
              >
                {selectedFile && selectedFile.length > 0
                  ? selectedFile.map((file: File) => file.name).join(', ')
                  : description}
              </Typography>
              <Controller
                render={() => (
                  <MUI.InputHtml
                    ref={fileInputRef}
                    id={name}
                    name={name}
                    type="file"
                    multiple
                    accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                    $display="none"
                    onChange={handleFileChange}
                  />
                )}
                control={control}
                name={`evidencePlanActions.${position}.evidence.attachments`}
              />
            </MUI.LabelHtml>
          </>
        )}
        {selectedFile && selectedFile.length > 0 && (
          <Box mt={2}>
            <Button variant="outlined" onClick={handleRemoveFile}>
              Remover
            </Button>
          </Box>
        )}
      </Box>
      {error && (
        <Box mt={1} ml={4}>
          <FormHelperText error id="component-error-text">
            Anexe um arquivo
          </FormHelperText>
        </Box>
      )}
    </Grid>
  )
}

export default DocumentAttachment
