import { DataTreatmentAgentType } from 'types/dataTreatmentAgents.types'
import constants from 'constants/index'

const agentsTypeFilter = (dataTreatmentAgents: DataTreatmentAgentType[]) => {
  const { TYPES } = constants.dataTreatmentAgents

  const controllers = dataTreatmentAgents?.filter(
    (dataTreatmentAgent) =>
      dataTreatmentAgent.agentType === TYPES.CONTROLLER_TYPE
  )

  const operators = dataTreatmentAgents?.filter(
    (dataTreatmentAgent) => dataTreatmentAgent.agentType === TYPES.OPERATOR_TYPE
  )

  const suboperators = dataTreatmentAgents?.filter(
    (dataTreatmentAgent) =>
      dataTreatmentAgent.agentType === TYPES.SUBOPERATOR_TYPE
  )

  const coControllers = dataTreatmentAgents?.filter(
    (dataTreatmentAgent) =>
      dataTreatmentAgent.agentType === TYPES.CO_CONTROLLER_TYPE
  )

  return { controllers, operators, suboperators, coControllers }
}

export default agentsTypeFilter
