const PENDING_STATUS = 1 // PENDENTE WEB
const AWAITING_REVIEW_STATUS = 2 // AGUARDANDO REVISÃO WEB
const APPROVED_STATUS = 4 // APROVADO
const REVIEWING_STATUS = 5 // EM REVISÃO WEB
const PENDING_LIA_STATUS = 10 // AGUARDANDO LIA NO ADVISOR
const REVIEW_LIA_CLIENT_STATUS = 11 // PENDENTE LIA NO ADVISOR
const REVIEW_LIA_DPO_STATUS = 12 //  EM REVISÃO NO ADVISOR
const DISAPPROVED_LIA_STATUS = 13 // REPROVADOS NO ADVISOR

const STATUSES_OPTIONS = [
  { id: APPROVED_STATUS, name: 'Aprovado' },
  { id: PENDING_LIA_STATUS, name: 'Aguardando LIA' },
  { id: REVIEW_LIA_CLIENT_STATUS, name: 'Pendente LIA' },
  { id: REVIEW_LIA_DPO_STATUS, name: 'Em revisão LIA' },
  { id: DISAPPROVED_LIA_STATUS, name: 'Reprovado LIA' },
]

const STATUSES_LIA = {
  PENDING_LIA_STATUS,
  REVIEW_LIA_CLIENT_STATUS,
  REVIEW_LIA_DPO_STATUS,
  DISAPPROVED_LIA_STATUS,
  APPROVED_STATUS,
}

const ALL_STATUS_LIA = [
  APPROVED_STATUS,
  PENDING_LIA_STATUS,
  REVIEW_LIA_CLIENT_STATUS,
  REVIEW_LIA_DPO_STATUS,
  DISAPPROVED_LIA_STATUS,
] as const

const NATURE_OF_PERSONAL_DATA = 'nature_of_personal_data'
const DATA_CHILDREN_TEENAGERS = 'data_children_teenagers'
const LEGITIMATE_INTEREST_FINALITY = 'legitimate_interest_finality'
const CONCRETE_SITUATION = 'concrete_situation'

const FINALITY_KINDS = [
  NATURE_OF_PERSONAL_DATA,
  DATA_CHILDREN_TEENAGERS,
  LEGITIMATE_INTEREST_FINALITY,
  CONCRETE_SITUATION,
]

const TREATMENT_INTENDED_PURPOSE = 'treatment_intended_purpose'
const MINIMIZATION = 'minimization'
const LEGITIMATE_EXPECTATION = 'legitimate_expectation'
const RISKS_IMPACTS_FUNDAMENTAL_RIGHTS_FREEDOMS =
  'risks_impacts_fundamental_rights_freedoms'
const SAFEGUARDS_AND_MECHANISMS = 'safeguards_and_mechanisms'

const CONCLUSION_LIA = 'conclusion_lia'

const NECESSITY_KINDS = [TREATMENT_INTENDED_PURPOSE, MINIMIZATION]

const BALANCING_SAFEGUARDING_KINDS = [
  LEGITIMATE_EXPECTATION,
  RISKS_IMPACTS_FUNDAMENTAL_RIGHTS_FREEDOMS,
  SAFEGUARDS_AND_MECHANISMS,
]

const ALL_KINDS = {
  CONCRETE_SITUATION,
  DATA_CHILDREN_TEENAGERS,
  LEGITIMATE_EXPECTATION,
  LEGITIMATE_INTEREST_FINALITY,
  MINIMIZATION,
  NATURE_OF_PERSONAL_DATA,
  RISKS_IMPACTS_FUNDAMENTAL_RIGHTS_FREEDOMS,
  SAFEGUARDS_AND_MECHANISMS,
  TREATMENT_INTENDED_PURPOSE,
}

const LIA_QUESTION_KINDS = {
  ALL_KINDS,
  BALANCING_SAFEGUARDING_KINDS,
  FINALITY_KINDS,
  NECESSITY_KINDS,
  CONCLUSION_LIA,
}

const DPO = 'dpo'
const CLIENT = 'client'

const ROLES = { DPO, CLIENT }

const PENDING = 'pending'
const DISAPPROVED = 'disapproved'
const REVIEW_DISAPPROVED = 'review_disapproved'

const STATUSES_LIA_ANSWER = { PENDING, DISAPPROVED, REVIEW_DISAPPROVED }

const STATUSES_LABEL = [
  {
    label: 'TODOS',
    value: [
      APPROVED_STATUS,
      DISAPPROVED_LIA_STATUS,
      PENDING_LIA_STATUS,
      REVIEW_LIA_CLIENT_STATUS,
      REVIEW_LIA_DPO_STATUS,
    ],
  },
  {
    label: 'APROVADO',
    value: [APPROVED_STATUS],
  },
  {
    label: 'PENDENTE LIA',
    value: [REVIEW_LIA_CLIENT_STATUS],
  },
  {
    label: 'EM REVISÃO LIA',
    value: [REVIEW_LIA_DPO_STATUS],
  },
  {
    label: 'REPROVADOS LIA',
    value: [DISAPPROVED_LIA_STATUS],
  },
  {
    label: 'AGUARDANDO LIA',
    value: [PENDING_LIA_STATUS],
  },
]

const CHIP_STATUS_BACKGROUND_COLOR = {
  [APPROVED_STATUS]: '#4CAF50',
  [PENDING_LIA_STATUS]: '#087DF1',
  [REVIEW_LIA_CLIENT_STATUS]: '#087DF1',
  [REVIEW_LIA_DPO_STATUS]: '#FF9800',
  [DISAPPROVED_LIA_STATUS]: '#B71C1C',
}

const CHIP_STATUS_LABEL = {
  [APPROVED_STATUS]: 'APROVADO',
  [PENDING_LIA_STATUS]: 'AGUARDANDO LIA',
  [REVIEW_LIA_CLIENT_STATUS]: 'PENDENTE LIA',
  [REVIEW_LIA_DPO_STATUS]: 'EM REVISÃO LIA',
  [DISAPPROVED_LIA_STATUS]: 'REPROVADO LIA',
}

export type StatusLiaIdType = (typeof ALL_STATUS_LIA)[number]

const COLORS = {
  lightBlue: '#B8DAF7',
  greenLight: '#E3FFBB',
}

const STEPS_DPO = {
  FINALITY: 0,
  NECESSITY: 1,
  BALANCING_SAFEGUARDING: 2,
  CONCLUSION: 3,
}

const LEGAL_FRAMEWORKS_LIA = {
  LEGITIMATE_INTEREST: 'Legítimo interesse do controlador ou de terceiro',
  FRAUD_PREVENTION: 'Prevenção à fraude e segurança do titular',
} as const

export type LegalFrameworksLiaType = typeof LEGAL_FRAMEWORKS_LIA

const QUESTION_LEGITIMATE_INTEREST = 37
const QUESTION_PREVENT_FRAUD = 38

const QUESTIONS_POSSIBLE_USE_DATA_TREATMENT = {
  QUESTION_LEGITIMATE_INTEREST,
  QUESTION_PREVENT_FRAUD,
} as const

export type QuestionOrderType = typeof QUESTIONS_POSSIBLE_USE_DATA_TREATMENT

const DATA_PROCESS_STATUSES_LABEL: { [key: number]: string } = {
  [APPROVED_STATUS]: 'Aprovado',
  [PENDING_LIA_STATUS]: 'Aguardando lia',
  [REVIEW_LIA_CLIENT_STATUS]: 'Pendente lia',
  [REVIEW_LIA_DPO_STATUS]: 'Em revisão lia',
  [DISAPPROVED_LIA_STATUS]: 'Reprovado lia',
  [PENDING_STATUS]: 'Pendente',
  [AWAITING_REVIEW_STATUS]: 'Aguardando revisão',
  [REVIEWING_STATUS]: 'Em revisão',
}

const legitimateInterest = {
  CHIP_STATUS_BACKGROUND_COLOR,
  CHIP_STATUS_LABEL,
  COLORS,
  DATA_PROCESS_STATUSES_LABEL,
  LEGAL_FRAMEWORKS_LIA,
  LIA_QUESTION_KINDS,
  QUESTIONS_POSSIBLE_USE_DATA_TREATMENT,
  ROLES,
  STATUSES_LABEL,
  STATUSES_LIA_ANSWER,
  STATUSES_LIA,
  STATUSES_OPTIONS,
  STEPS_DPO,
}

export default legitimateInterest
