import * as yup from 'yup'

const planActionSchema = yup.object({
  what: yup.string().max(1000).notRequired(),
  how: yup.string().max(1000).notRequired(),
  because: yup.string().max(1000).notRequired(),
  where: yup.string().max(1000).notRequired(),
  howPrice: yup.string().max(18).notRequired(),
  link: yup
    .string()
    .url('O link deve ser uma URL válida')
    .max(250)
    .notRequired(),
})

const schema = yup.object({
  evidencePlanActions: yup.array().of(
    yup.object({
      id: yup.mixed().optional(),
      evidence: yup.object({
        title: yup.string().max(1000).required(),
        description: yup.string().max(1000),
        justify: yup.string().max(1000),
        attachments: yup.mixed().nullable(),
      }),
      planActions: yup.array().of(planActionSchema).nullable(),
    })
  ),
})

export type PlanActionSchemaType = yup.InferType<typeof planActionSchema>

export type QuestionFormSchemaType = yup.InferType<typeof schema>
export type QuestionFormControlType = keyof QuestionFormSchemaType

export default schema
