import * as yup from 'yup'

const schema = yup.object({
  kind: yup.string().max(100).required(),
  visible: yup.boolean().required(),
  interaction: yup
    .string()
    .oneOf(['global', 'diagnostic', 'governance_project'])
    .required(),
})

export type GeneralInformationCardSchemaType = yup.InferType<typeof schema>

export default schema
