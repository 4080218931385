import { Edit2 as Edit2Icon, PlusCircle as PlusCircleIcon } from 'react-feather'
import { Box, Button, Grid, Typography, useMediaQuery } from '@mui/material'

import { QuestionMenuTitleType } from './questionMenuTitle.types'

import theme from 'theme'

const QuestionMenuTitle = ({
  title,
  optionsMenuType,
  setOpenEditQuestionModal,
  questionId,
  setQuestionData,
  setOpenNewQuestionModal,
  disableButton = false,
  integrationKey,
}: QuestionMenuTitleType) => {
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  })

  const handleOpenEditQuestionModal = () => {
    setQuestionData({ title, questionId, integrationKey })
    setOpenEditQuestionModal(true)
  }

  const handleOpenNewQuestionModal = () => {
    setQuestionData({ questionId })
    setOpenNewQuestionModal(true)
  }

  return (
    <Box>
      <Grid container spacing={4}>
        <Grid
          item
          xs={12}
          sm={5}
          lg={7}
          display="flex"
          alignItems="center"
          justifyContent={isSmall ? 'center' : 'start'}
        >
          <Typography fontWeight={500} textAlign={isSmall ? 'center' : 'start'}>
            {title}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={7}
          lg={5}
          component={Box}
          display="flex"
          flexDirection="row"
          justifyContent={isSmall ? 'center' : 'end'}
          gap={5}
        >
          <Button
            variant="outlined"
            startIcon={<Edit2Icon size={15} />}
            aria-label="Abrir modal para editar o nome da pergunta"
            onClick={handleOpenEditQuestionModal}
            disabled={disableButton}
          >
            Editar Pergunta
          </Button>
          {(optionsMenuType === 'suboption' ||
            optionsMenuType === 'answer') && (
            <Button
              variant="contained"
              color="secondary"
              startIcon={<PlusCircleIcon size={15} />}
              aria-label="Ir para criação de resposta"
              onClick={handleOpenNewQuestionModal}
              disabled={disableButton}
            >
              Criar nova resposta
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default QuestionMenuTitle
