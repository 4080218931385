import * as yup from 'yup'

const schema = yup.object({
  title: yup.string().max(250).required(),
  status: yup.boolean().required(),
  integrationValue: yup.string().max(250),
})

export type AnswerFormSchemaType = yup.InferType<typeof schema>

export default schema
