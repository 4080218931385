import { useState } from 'react'

import {
  Box,
  Collapse,
  Divider,
  FormLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'

import { LoadingFeedback } from 'components'

import {
  ChevronUp as ChevronUpIcon,
  ChevronDown as ChevronDownIcon,
  Trash2 as TrashIcon,
} from 'react-feather'

import { Controller, useFormContext } from 'react-hook-form'
import { useSnackbar } from 'hooks'

import service from 'service'
import { formatters } from 'helpers'

import { EvidenceFormSectionType } from './evidenceFormSection.types'

import DocumentAttachment from '../DocumentAttachment'

import { QuestionFormSchemaType } from '../../schema'
import { EvidencePlanAction, PlainFileType } from '../../ActionPlanForm.types'

const EvidenceFormSection = ({
  open,
  setOpen,
  quizQuestionTemplateId,
  position,
  evidencePlanActionId,
  handleDestroyRegister,
  evidencePlanActions,
  setEvidencePlanActions,
  setRegisterName,
}: EvidenceFormSectionType) => {
  const [loading, setLoading] = useState(false)
  const { snackbar } = useSnackbar()
  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<QuestionFormSchemaType>()
  const [file, setFile] = useState<File[] | null>([])

  const attachments = evidencePlanActions[position]?.evidence?.attachments?.map(
    (file) => ({
      name: file.name,
      url: file instanceof File ? URL.createObjectURL(file) : file.url,
      id: file instanceof File ? null : file?.id,
    })
  ) as PlainFileType[]

  const deleteDocument = async (documentId?: number, callback?: () => void) => {
    try {
      if (!documentId) return
      setLoading(true)
      await service.dponet.evidencePlanActions.destroyDocument({
        quizQuestionTemplateId,
        evidencePlanActionId,
        documentId,
      })

      if (callback) callback()
      snackbar.open({
        message: 'Documento excluído com sucesso',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message:
          formatters.errorMessage(error) ||
          'Ocorreu um erro ao excluir o documento',
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const handleDestroy = async () => {
    try {
      if (evidencePlanActionId) {
        setLoading(true)

        await service.dponet.evidencePlanActions.destroy({
          quizQuestionTemplateId,
          evidencePlanActionId,
        })
      }

      const newState = (
        (watch('evidencePlanActions') ?? []) as EvidencePlanAction[]
      ).filter((prevEvidencePlanAction, index) => {
        if (evidencePlanActionId) {
          return prevEvidencePlanAction.id !== evidencePlanActionId
        }

        return index !== position
      })

      setEvidencePlanActions(newState)
      setValue('evidencePlanActions', newState)
      snackbar.open({
        message: 'Evidência com plano de ação excluída com sucesso',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message:
          formatters.errorMessage(error) ||
          'Ocorreu um erro ao excluir a evidência com plano de ação',
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Grid item xs={12}>
      <LoadingFeedback open={loading} />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={4}
        my={4}
      >
        <Typography variant="subtitle1" fontWeight={600}>
          {watch(`evidencePlanActions.${position}.evidence.title`) ||
            'Nova evidência'}
        </Typography>
        <Box>
          <IconButton onClick={() => setOpen(!open)}>
            {open ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </IconButton>
          <IconButton
            onClick={() =>
              handleDestroyRegister(() => {
                setRegisterName()
                return handleDestroy
              })
            }
          >
            <TrashIcon />
          </IconButton>
        </Box>
      </Box>
      <Divider />
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box mt={4} px={4}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Box display="flex" flexDirection="column">
                <FormLabel>Nome</FormLabel>
                <Box py={2}>
                  <Typography variant="caption" color={'GrayText'}>
                    Nome da evidência que será utilizada para comprovar a ação
                  </Typography>
                </Box>
              </Box>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={
                      !!errors?.evidencePlanActions?.[position]?.evidence?.title
                        ?.message
                    }
                    helperText={
                      errors?.evidencePlanActions?.[position]?.evidence?.title
                        ?.message
                    }
                    type="text"
                    variant="outlined"
                    fullWidth
                  />
                )}
                control={control}
                name={`evidencePlanActions.${position}.evidence.title`}
              />
            </Grid>
            <Grid item xs={12}>
              <Box paddingTop={4} marginRight={2}>
                <FormLabel>Descrição</FormLabel>
                <Box py={2}>
                  <Typography variant="caption" color={'GrayText'}>
                    Descreva os detalhes da evidência e como ela será utilizada
                  </Typography>
                </Box>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={
                        !!errors?.evidencePlanActions?.[position]?.evidence
                          ?.description?.message
                      }
                      helperText={
                        errors?.evidencePlanActions?.[position]?.evidence
                          ?.description?.message
                      }
                      type="text"
                      variant="outlined"
                      multiline
                      rows={3}
                      fullWidth
                    />
                  )}
                  control={control}
                  name={`evidencePlanActions.${position}.evidence.description`}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box paddingTop={4} marginRight={2}>
                <FormLabel>Justificativa</FormLabel>
                <Box py={2}>
                  <Typography variant="caption" color={'GrayText'}>
                    Explique o motivo da escolha desta evidência
                  </Typography>
                </Box>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={
                        !!errors?.evidencePlanActions?.[position]?.evidence
                          ?.justify?.message
                      }
                      helperText={
                        errors?.evidencePlanActions?.[position]?.evidence
                          ?.justify?.message
                      }
                      multiline
                      rows={3}
                      type="text"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                  control={control}
                  name={`evidencePlanActions.${position}.evidence.justify`}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <DocumentAttachment
                position={position}
                name="files"
                selectedFile={file}
                setSelectedFile={setFile}
                attachment={attachments}
                description="Clique aqui para anexar arquivo"
                deleteDocument={deleteDocument}
              />
            </Grid>
          </Grid>
        </Box>
      </Collapse>
    </Grid>
  )
}

export default EvidenceFormSection
