import { Fragment } from 'react'
import { size } from 'lodash'
import { Box } from '@mui/material'

import { TitleSection, TopicSection, DataProcessInfoChip } from '..'

import { dataProcesses } from 'helpers'

import { DataTreatmentAgentsType } from './dataTreatmentAgents.types'
import { DataTreatmentAgentType } from 'types/dataTreatmentAgents.types'

import constants from 'constants/index'

const DataTreatmentAgents = ({
  dataTreatmentAgents = [],
}: DataTreatmentAgentsType) => {
  const { LABELS } = constants.dataTreatmentAgents

  const { agentsTypeFilter } = dataProcesses

  const { controllers, operators, suboperators, coControllers } =
    agentsTypeFilter(dataTreatmentAgents)

  const renderSection = (label: string, items: DataTreatmentAgentType[]) =>
    size(items) > 0 && (
      <Fragment>
        <TopicSection text={label} />
        <Box display="flex" mt={0.5}>
          {items?.map((item) => (
            <DataProcessInfoChip key={item.id} text={item?.name} />
          ))}
        </Box>
      </Fragment>
    )

  return (
    <Box m={4}>
      <TitleSection title="Agentes de tratamento" />
      {renderSection(LABELS.CONTROLLER_LABEL, controllers)}
      {renderSection(LABELS.OPERATOR_LABEL, operators)}
      {renderSection(LABELS.SUBOPERATOR_LABEL, suboperators)}
      {renderSection(LABELS.CO_CONTROLLER_LABEL, coControllers)}
    </Box>
  )
}

export default DataTreatmentAgents
