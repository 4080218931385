import auth from './auth'
import company from './company'
import daiFeedback from './daiFeedback'
import dataProcesses from './dataProcesses'
import dataProcessTemplate from './dataProcessTemplate'
import dataProcessTemplateCompany from './dataProcessTemplateCompany'
import dataProcessTemplateQuestion from './dataProcessTemplateQuestion'
import evidencePlanActions from './evidencePlanActions'
import faqs from './faqs'
import incidents from './incidents'
import legalFrameworks from './legalFrameworks'
import legalTickets from './legalTickets'
import liaAnswer from './liaAnswer'
import liaQuestion from './liaQuestion'
import observer from './observer'
import organization from './organization'
import organizationCompany from './organizationCompany'
import organizationStats from './organizationStats'
import organizationUser from './organizationUser'
import outsourcedDpo from './outsourcedDpo'
import privacyPortal from './privacyPortal'
import profiles from './profiles'
import questionnaires from './questionnaires'
import tags from './tags'
import tasks from './tasks'
import ticket from './ticket'
import tomticket from './tomticket'
import twoFactorAuthentication from './twoFactorAuthentication'
import user from './user'
import whiteLabel from './whiteLabel'

export default {
  auth,
  company,
  daiFeedback,
  dataProcesses,
  dataProcessTemplate,
  dataProcessTemplateCompany,
  dataProcessTemplateQuestion,
  evidencePlanActions,
  faqs,
  incidents,
  legalFrameworks,
  legalTickets,
  liaAnswer,
  liaQuestion,
  observer,
  organization,
  organizationCompany,
  organizationStats,
  organizationUser,
  outsourcedDpo,
  privacyPortal,
  profiles,
  questionnaires,
  tags,
  tasks,
  ticket,
  tomticket,
  twoFactorAuthentication,
  user,
  whiteLabel,
}
