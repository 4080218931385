/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react'
import { Box, Grid } from '@mui/material'

import { BasicDialog, LoadingFeedback } from 'components'
import { QuestionTable, QuestionMenuTitle, QuestionForm, AnswerForm } from '../'

import { useFetch } from 'hooks'

import { PrivacyPortalFormType } from './privacyPortalForm.types'
import { GetQuestionOptionsInterface } from 'interfaces/privacyPortal.interfaces'
import { QuestionDataType } from '../QuestionMenuTitle/questionMenuTitle.types'

import constants from 'constants/index'
import service from 'service'

const PrivacyPortalForm = ({
  companyId,
  companyDocument,
  loadingCompany,
  selectedTab,
  isPermittedManageForm,
}: PrivacyPortalFormType) => {
  const [openEditQuestionModal, setOpenEditQuestionModal] = useState(false)
  const [questionFormIsLoading, setQuestionFormIsLoading] = useState(false)
  const [openNewQuestionModal, setOpenNewQuestionModal] = useState(false)
  const [questionData, setQuestionData] = useState<QuestionDataType>()

  const { response, loading, refresh } = useFetch(
    service.dponet.privacyPortal.getQuestions,
    {
      companyId: companyId,
    },
    [companyDocument],
    !!companyDocument &&
      selectedTab === constants.privacyPortal.FORM_CONFIGURATION_ID
  )

  const questionOptions: GetQuestionOptionsInterface[] =
    response?.data?.questionOptionTickets

  const handleCloseEditQuestionModal = () => {
    setOpenEditQuestionModal(false)
  }

  const handleCloseNewQuestionModal = () => {
    setOpenNewQuestionModal(false)
  }

  return (
    <Grid container spacing={6}>
      <LoadingFeedback
        open={!!(companyDocument && (loadingCompany || loading))}
      />
      {questionOptions?.map((question) => (
        <Grid key={question?.id} item xs={12}>
          <Box display="flex" flexDirection="column" gap={5}>
            <Box pl={4}>
              <QuestionMenuTitle
                optionsMenuType="answer"
                title={question?.title}
                questionId={question?.id}
                setOpenEditQuestionModal={setOpenEditQuestionModal}
                setOpenNewQuestionModal={setOpenNewQuestionModal}
                setQuestionData={setQuestionData}
                disableButton={!isPermittedManageForm}
                integrationKey={question?.integrationKey}
              />
            </Box>
            <QuestionTable
              kind={question?.kind}
              companyId={companyId}
              answerOptionsTickets={question?.answerOptionTickets}
              questionOptionTicketId={question?.id}
              disableButton={!isPermittedManageForm}
            />
          </Box>
        </Grid>
      ))}
      <BasicDialog
        open={openEditQuestionModal}
        onClose={handleCloseEditQuestionModal}
        title="Editar pergunta"
        isSaving={questionFormIsLoading}
        form="privacy-portal-question-form"
      >
        <QuestionForm
          isEdit
          onClose={handleCloseEditQuestionModal}
          refresh={refresh}
          setIsLoading={setQuestionFormIsLoading}
          question={questionData}
        />
      </BasicDialog>
      <BasicDialog
        open={openNewQuestionModal}
        onClose={handleCloseNewQuestionModal}
        title="Nova resposta"
        isSaving={questionFormIsLoading}
        form="privacy-portal-answer-form"
      >
        <AnswerForm
          onClose={handleCloseNewQuestionModal}
          questionId={questionData?.questionId}
          refresh={refresh}
          setIsLoading={setQuestionFormIsLoading}
        />
      </BasicDialog>
    </Grid>
  )
}

export default PrivacyPortalForm
