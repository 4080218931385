import { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Trash2 as TrashIcon, PlusCircle as PlusIcon } from 'react-feather'
import { isEmpty } from 'lodash'
import {
  Box,
  Button,
  Collapse,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material'

import { formatters } from 'helpers'

import { PlanActionFormSectionType } from './planActionFormSection.types'
import { QuestionFormSchemaType } from '../../schema'
import { PlanAction } from '../../ActionPlanForm.types'

import service from 'service'

const PlanActionFormSection = ({
  open,
  quizQuestionTemplateId,
  evidencePlanActionId,
  planActions,
  positionEvidence,
  handleDestroyRegister,
  setRegisterName,
}: PlanActionFormSectionType) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<QuestionFormSchemaType>()

  const [planActionsState, setPlanActionsState] = useState(planActions)

  const handleAddPlanAction = () => {
    setPlanActionsState((prev) => [
      ...prev,
      {
        what: '',
        how: '',
        because: '',
        where: '',
        howPrice: '',
        link: '',
      },
    ])
  }

  const removePlanAction = async (
    planAction: PlanAction,
    planActionPosition: number
  ) => {
    if (planAction?.id) {
      await service.dponet.evidencePlanActions.destroyPlanAction({
        quizQuestionTemplateId,
        evidencePlanActionId,
        planActionid: planAction?.id,
      })
    }

    const newState = planActionsState.filter((prevPlanAction, index) => {
      if (planAction?.id) {
        return prevPlanAction.id !== planAction.id
      }

      return index !== planActionPosition
    })

    setPlanActionsState(newState)

    setValue(`evidencePlanActions.${positionEvidence}.planActions`, newState)
  }

  return (
    <Grid item xs={12}>
      <Collapse in={open}>
        <Grid container spacing={4}>
          {!isEmpty(planActionsState) && (
            <Grid item xs={12}>
              <Box px={4}>
                {planActionsState.map((planAction, positionPlanAction) => (
                  <Grid
                    key={`${positionEvidence}-${positionPlanAction}`}
                    item
                    xs={12}
                  >
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          pt={4}
                        >
                          <Typography variant="h6">Plano de ação</Typography>
                          <IconButton
                            onClick={() =>
                              handleDestroyRegister(() => {
                                setRegisterName()
                                return () =>
                                  removePlanAction(
                                    planAction,
                                    positionPlanAction
                                  )
                              })
                            }
                          >
                            <TrashIcon />
                          </IconButton>
                        </Box>
                        <Box paddingTop={4} marginRight={2}>
                          <FormLabel>O que (opcional)</FormLabel>
                          <Box py={2}>
                            <Typography variant="caption" color={'GrayText'}>
                              Ações concretas e objetivas que serão tomadas
                            </Typography>
                          </Box>
                          <Controller
                            render={({ field }) => (
                              <TextField
                                {...field}
                                error={
                                  !!errors?.evidencePlanActions?.[
                                    positionEvidence
                                  ]?.planActions?.[positionPlanAction]?.what
                                }
                                helperText={
                                  errors?.evidencePlanActions?.[
                                    positionEvidence
                                  ]?.planActions?.[positionPlanAction]?.what
                                    ?.message
                                }
                                type="text"
                                variant="outlined"
                                fullWidth
                                multiline
                                minRows={3}
                              />
                            )}
                            control={control}
                            name={`evidencePlanActions.${positionEvidence}.planActions.${positionPlanAction}.what`}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box paddingTop={4} marginRight={2}>
                          <FormLabel>Como (opcional)</FormLabel>
                          <Box py={2}>
                            <Typography variant="caption" color={'GrayText'}>
                              Planejamento para execução da ação
                            </Typography>
                          </Box>
                          <Controller
                            render={({ field }) => (
                              <TextField
                                {...field}
                                error={
                                  !!errors?.evidencePlanActions?.[
                                    positionEvidence
                                  ]?.planActions?.[positionPlanAction]?.how
                                }
                                helperText={
                                  errors?.evidencePlanActions?.[
                                    positionEvidence
                                  ]?.planActions?.[positionPlanAction]?.how
                                    ?.message
                                }
                                type="text"
                                variant="outlined"
                                fullWidth
                                multiline
                                minRows={3}
                              />
                            )}
                            control={control}
                            name={`evidencePlanActions.${positionEvidence}.planActions.${positionPlanAction}.how`}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box paddingTop={4} marginLeft={2}>
                          <FormLabel>Por que (opcional)</FormLabel>
                          <Box py={2}>
                            <Typography variant="caption" color={'GrayText'}>
                              A meta que se objetiva com a ação
                            </Typography>
                          </Box>
                          <Controller
                            render={({ field }) => (
                              <TextField
                                {...field}
                                error={
                                  !!errors?.evidencePlanActions?.[
                                    positionEvidence
                                  ]?.planActions?.[positionPlanAction]?.because
                                }
                                helperText={
                                  errors?.evidencePlanActions?.[
                                    positionEvidence
                                  ]?.planActions?.[positionPlanAction]?.because
                                    ?.message
                                }
                                type="text"
                                variant="outlined"
                                fullWidth
                                multiline
                                minRows={3}
                              />
                            )}
                            control={control}
                            name={`evidencePlanActions.${positionEvidence}.planActions.${positionPlanAction}.because`}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box paddingTop={4} marginRight={2}>
                          <FormLabel>Onde (opcional)</FormLabel>
                          <Box py={2}>
                            <Typography variant="caption" color={'GrayText'}>
                              Em qual setor a ação será realizada
                            </Typography>
                          </Box>
                          <Controller
                            render={({ field }) => (
                              <TextField
                                {...field}
                                error={
                                  !!errors?.evidencePlanActions?.[
                                    positionEvidence
                                  ]?.planActions?.[positionPlanAction]?.where
                                }
                                helperText={
                                  errors?.evidencePlanActions?.[
                                    positionEvidence
                                  ]?.planActions?.[positionPlanAction]?.where
                                    ?.message
                                }
                                type="text"
                                variant="outlined"
                                fullWidth
                              />
                            )}
                            control={control}
                            name={`evidencePlanActions.${positionEvidence}.planActions.${positionPlanAction}.where`}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box paddingTop={4} marginLeft={2}>
                          <FormLabel>Quanto (opcional)</FormLabel>
                          <Box py={2}>
                            <Typography variant="caption" color={'GrayText'}>
                              Impacto financeiro da ação
                            </Typography>
                          </Box>
                          <Controller
                            render={({ field }) => (
                              <TextField
                                {...field}
                                error={
                                  !!errors?.evidencePlanActions?.[
                                    positionEvidence
                                  ]?.planActions?.[positionPlanAction]?.howPrice
                                }
                                helperText={
                                  errors?.evidencePlanActions?.[
                                    positionEvidence
                                  ]?.planActions?.[positionPlanAction]?.howPrice
                                    ?.message
                                }
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Typography>R$</Typography>
                                    </InputAdornment>
                                  ),
                                }}
                                type="text"
                                variant="outlined"
                                fullWidth
                                onChange={(event) =>
                                  field.onChange(
                                    formatters.money.input(event.target.value)
                                  )
                                }
                              />
                            )}
                            control={control}
                            name={`evidencePlanActions.${positionEvidence}.planActions.${positionPlanAction}.howPrice`}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box paddingY={4}>
                          <FormLabel>Link</FormLabel>
                          <Box py={2}>
                            <Typography variant="caption" color={'GrayText'}>
                              Link de referência para o preenchimento do plano
                              de ação
                            </Typography>
                          </Box>
                          <Controller
                            render={({ field }) => (
                              <TextField
                                {...field}
                                error={
                                  !!errors?.evidencePlanActions?.[
                                    positionEvidence
                                  ]?.planActions?.[positionPlanAction]?.link
                                }
                                helperText={
                                  errors?.evidencePlanActions?.[
                                    positionEvidence
                                  ]?.planActions?.[positionPlanAction]?.link
                                    ?.message
                                }
                                type="text"
                                variant="outlined"
                                fullWidth
                              />
                            )}
                            control={control}
                            name={`evidencePlanActions.${positionEvidence}.planActions.${positionPlanAction}.link`}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Box>
            </Grid>
          )}
          <Grid item xs={12}>
            <Box px={4} pb={4}>
              <Button onClick={handleAddPlanAction} startIcon={<PlusIcon />}>
                Adicionar plano de ação
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Collapse>
    </Grid>
  )
}

export default PlanActionFormSection
